import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  blueSVG: {
    color: theme.palette.primary.blue,
  },
}));

export default useStyles;

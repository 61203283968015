import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  blueTextTitle: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
    fontSize: '22px',
  },
  regularBlueText: {
    color: theme.palette.primary.blue,
    fontSize: theme.fontSizes.bodyText,
  },
  blueText: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
    fontSize: theme.fontSizes.bodyText,
  },
  blueButton: {
    backgroundColor: theme.palette.primary.blue,
    fontWeight: 'bold',
    color: 'white',
    width: '100%',
    maxWidth: '400px',
  },
  orangeTextTitle: {
    color: theme.palette.primary.orange,
    fontWeight: 700,
    fontSize: '22px',
  },
  orangeButton: {
    backgroundColor: theme.palette.primary.orange,
    fontWeight: 'bold',
    color: 'white',
    width: '100%',
    maxWidth: '400px',
    marginTop: '15px',
  },
  sendButton: {
    backgroundColor: '#d9d9d9',
    fontSize: theme.fontSizes.bodyText,
    fontWeight: 700,
    textAlign: 'center',
    border: '3px solid',
    borderColor: theme.palette.primary.blue,
    color: theme.palette.primary.blue,
    marginLeft: '15px',
  },
  blueTextUnderlinedPayment: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
    textDecoration: 'underline',
    fontSize: theme.fontSizes.bodyText,
  },
  checkIcon: {
    color: theme.palette.primary.blue,
  },
  iconContainer: {
    height: '42px',
    width: '42px',
  },
}));

export default useStyles;

/* eslint-disable default-param-last */
/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import {
  RESET_CART,
  ADD_PRODUCT_CART,
  REMOVE_PRODUCT_CART,
  DELETE_PRODUCT_CART,
  RESET_PAYMENTS_CART,
  ADD_PAYMENTS_CART,
  UPDATE_PAYMENTS_CART,
  RESET_RESERVED_STOCK_CART,
  ADD_RESERVED_STOCK_CART,
  UPDATE_CUSTOM_PRICE_CART,
  RESET_TOTAL_CART_AMOUNT,
  SET_TOTAL_CART_AMOUNT,
  SET_LOADING_SALE_CART,
} from '../actions/cartActions';

const initialState = {
  productCart: null,
  paymentsCart: null,
  reservedStockIds: null,
  totalCartAmount: null,
  loading: true,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CART: {
      return produce(state, (draft) => {
        draft.productCart = null;
      });
    }

    case ADD_PRODUCT_CART: {
      return produce(state, (draft) => {
        const {
          productId: id,
          quantityToAdd,
          quantity,
          ...rest
        } = action.payload;
        const availableStock = quantity || action.payload.availableStock;
        const actualCartState = state.productCart;

        if (actualCartState) {
          if (actualCartState[id]) {
            const itemsInCart = actualCartState[id].quantityInCart;
            draft.productCart = {
              ...actualCartState,
              [id]: { ...rest, availableStock, quantityInCart: itemsInCart + quantityToAdd },
            };
          } else {
            draft.productCart = {
              ...actualCartState,
              [id]: { ...rest, availableStock, quantityInCart: quantityToAdd },
            };
          }
        } else {
          draft.productCart = { [id]: { ...rest, availableStock, quantityInCart: quantityToAdd } };
        }
      });
    }

    case REMOVE_PRODUCT_CART: {
      return produce(state, (draft) => {
        const { productId: id, quantityToRemove, ...rest } = action.payload;
        const actualCartState = state.productCart;

        if (actualCartState[id]) {
          const lastStock = actualCartState[id].quantityInCart;
          if (lastStock > 0) {
            draft.productCart = {
              ...actualCartState,
              [id]: { ...rest, quantityInCart: lastStock - quantityToRemove },
            };
          }
        }
      });
    }

    case DELETE_PRODUCT_CART: {
      return produce(state, (draft) => {
        const { productId: id } = action.payload;
        const actualCartState = { ...state.productCart };

        delete actualCartState[id];

        draft.productCart = { ...actualCartState };
      });
    }

    case RESET_PAYMENTS_CART: {
      return produce(state, (draft) => {
        draft.paymentsCart = null;
      });
    }

    case ADD_PAYMENTS_CART: {
      return produce(state, (draft) => {
        draft.paymentsCart = action.payload;
      });
    }

    case UPDATE_PAYMENTS_CART: {
      return produce(state, (draft) => {
        draft.paymentsCart = action.payload;
      });
    }

    case RESET_RESERVED_STOCK_CART: {
      return produce(state, (draft) => {
        draft.reservedStockIds = null;
      });
    }

    case ADD_RESERVED_STOCK_CART: {
      return produce(state, (draft) => {
        draft.reservedStockIds = action.payload;
      });
    }

    case UPDATE_CUSTOM_PRICE_CART: {
      return produce(state, (draft) => {
        const { productId, customPrice } = action.payload;
        const actualCartState = state.productCart;
        const actualProduct = actualCartState[productId];
        if (actualProduct) {
          draft.productCart = {
            ...actualCartState,
            [productId]: { ...actualProduct, customPrice },
          };
        }
      });
    }

    case RESET_TOTAL_CART_AMOUNT: {
      return produce(state, (draft) => {
        draft.totalCartAmount = null;
      });
    }

    case SET_TOTAL_CART_AMOUNT: {
      return produce(state, (draft) => {
        draft.totalCartAmount = action.payload;
      });
    }

    case SET_LOADING_SALE_CART: {
      return produce(state, (draft) => {
        draft.loading = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default cartReducer;

import React, { useEffect, useState } from 'react';
import {
  Box, IconButton,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import {
  Search as SearchIcon,
} from 'react-feather';

import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';

import CustomTextField from '../../../components/Material/CustomTextfield';
import useStyles from '../styles';
import { setReturnBillFilter, setReturnsDateFilter } from '../../../actions/returnsActions';

function ReturnsFilter() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState(null);
  const [billNumber, setBillNumber] = useState('');

  const handleSetQuery = () => {
    dispatch(setReturnBillFilter(billNumber));
  };

  useEffect(() => {
    dispatch(setReturnsDateFilter(selectedDate));
  }, [selectedDate]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      mt={2}
    >
      <Box
        width='100%'
      >
        <Box
          mb={2}
          textAlign='center'
        >
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={esLocale}
          >
            <KeyboardDatePicker
              fullWidth
              autoOk
              clearable
              variant="dialog"
              inputVariant="outlined"
              placeholder="dd/mm/yyyy"
              format="dd/MM/yyyy"
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              className={classes.datePicker}
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box
          width='100%'
          display='flex'
        >
          <CustomTextField
            fullWidth
            value={billNumber}
            onChange={(e) => setBillNumber(e.target.value)}
            color="secondary"
            placeholder='Búsqueda por boleta o monto'
            variant="outlined"
          />
          <IconButton
            className={classes.blueSearchIcon}
            onClick={handleSetQuery}
          >
            <SearchIcon />
          </IconButton>
        </Box>

      </Box>
    </Box>
  );
}

export default ReturnsFilter;

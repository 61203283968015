import React from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setStore } from '../../actions/sessionActions';
import CustomTitleTypography from '../../components/Material/CustomTitlesTypography';
import CustomButton from '../../components/Material/CustomButton';

function CheckStore({ stores }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { rdcPayment } = useSelector((state) => state.session.moduleInformation);

  const handleStoreSelection = (storeInfo) => {
    dispatch(setStore(storeInfo));
    if (rdcPayment) {
      history.push('/app/maquinas');
    } else {
      history.push('/store-options');
    }
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      flex={1}
    >
      <CustomTitleTypography
        firstHighlight='Seleccionar'
        secondHighlight='Tienda'
        blueFirst={true}
      />
      <Box
        mt={6}
        width='100%'
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        {stores.map((btn) => (
          <Box
            key={btn.id}
            marginBottom='16px'
            width='100%'
          >
            <CustomButton
              type="submit"
              fullWidth
              color='primary'
              variant="contained"
              buttoncolor='blue'
              onClick={() => handleStoreSelection(btn)}
            >
              {btn.name}
            </CustomButton>
          </Box>
        ))}
      </Box>

    </Box>
  );
}

CheckStore.propTypes = {

};

CheckStore.defaultProps = {
  onSubmitSuccess: () => { },
};

export default CheckStore;

import React from 'react';

function Logo({ className, imgUrl }) {
  return (
    <img
      alt="Logo"
      src={imgUrl}
      className={className}
    />
  );
}

export default Logo;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Page from '../../components/Layout/Page';
import ClientTypeSelector from './ClientTypeSelector';
import { addCustomer } from '../../actions/customerActions';
import CustomContainer from '../../components/Material/CustomContainer';

function ClientTypeView() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { clientManagement } = useSelector((s) => s.session.moduleInformation);

  if (!clientManagement) {
    dispatch(addCustomer(null));
    history.push('/sale');
  }

  return (
    <Page
      title="Tipo Cliente"
    >
      <CustomContainer maxWidth="md">
        <ClientTypeSelector />
      </CustomContainer>
    </Page>
  );
}

export default ClientTypeView;

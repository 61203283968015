import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  SvgIcon,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChevronLeft as ChevronLeftIcon,
  Search as SearchIcon,
} from 'react-feather';

import useStyles from './styles';
import CustomTextField from '../../components/Material/CustomTextfield';
import { searchQueryProducts } from '../../actions/productActions';

function Header() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { clientManagement } = useSelector((s) => s.session.moduleInformation);
  const { loading } = useSelector((state) => state.cart);

  const [query, setQuery] = useState('');

  const handleSetQuery = () => {
    dispatch(searchQueryProducts(query));
  };

  const handleBack = () => {
    if (!clientManagement) {
      history.push('/store-options');
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    dispatch(searchQueryProducts(''));
  }, []);

  return (
    <Box
      display='flex'
      flexDirection='column'
      mb={2}
    >
      <Box
        display='flex'
        alignItems='center'
      >
        <SvgIcon
          fontSize='large'
          className={classes.blueSVG}
          onClick={handleBack}
        >
          <ChevronLeftIcon />
        </SvgIcon>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          flex={1}
          padding='0px 5px'
        >
          <CustomTextField
            fullWidth
            color="secondary"
            placeholder="Nombre, SKU, EAN"
            type="text"
            variant="outlined"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            disabled={loading}
          />
        </Box>
        <SvgIcon
          className={classes.blueSearchIcon}
          onClick={handleSetQuery}
        >
          <SearchIcon />
        </SvgIcon>
      </Box>
    </Box>
  );
}

export default Header;

import React from 'react';
import { Redirect, useHistory } from 'react-router';
import { Box } from '@material-ui/core';
import Page from '../../components/Layout/Page';
import LoginForm from './LoginForm';
import AuthService from '../../services/authService';

import useStyles from './styles';
import CustomContainer from '../../components/Material/CustomContainer';
import CustomTitleTypography from '../../components/Material/CustomTitlesTypography';
import Logo from '../../components/Layout/Logo';

function LoginView() {
  const classes = useStyles();
  const history = useHistory();

  if (AuthService.isAuthenticated()) {
    return <Redirect to="/check-store" />;
  }

  const handleSubmitSuccess = async () => {
    history.push('/check-store');
  };

  return (
    <Page
      title="Login"
    >
      <CustomContainer maxWidth="md">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="40vh"
        >
          <Logo imgUrl="/img/logos/logo_color.svg" className={classes.logo} />
          <Logo imgUrl="/img/logos/logo_compraqui.png" className={classes.logo} />
        </Box>
        <Box
          height="50vh"
        >
          <Box>
            <Box
              marginBottom="20px"
            >
              <CustomTitleTypography
                firstHighlight="Ingresa"
                secondHighlight="a tu cuenta"
              />
            </Box>
            <LoginForm onSubmitSuccess={handleSubmitSuccess} />
          </Box>
        </Box>
      </CustomContainer>
    </Page>
  );
}

export default LoginView;

import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Page from '../../components/Layout/Page';

import Header from './Components/Header';
import CreditSaleDetailTable from './Components/CreditSaleDetailTable';
import CreditSalePayment from './Components/CreditSalePaymentAction';
import CustomContainer from '../../components/Material/CustomContainer';
import handleApiResponse from '../../utils/handleApiResponse';
import { getCustomerDebt } from '../../requests/api/debts';
import { setDebtPaymentObj } from '../../actions/customerActions';

function CreditSaleDetailView() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { selectedCustomerDebt } = useSelector((state) => state.customer);

  const [allDebtPayments, setAllDebtPayments] = useState([]);
  const [lastCreditTaken, setLastCreditTaken] = useState('');

  useEffect(() => {
    const getAllDebtFromCustomer = async () => {
      try {
        const response = await getCustomerDebt(selectedCustomerDebt.id);
        let paymentOrder = [];
        const debtOrder = response.debt.map((item) => ({
          createdAt: item.createdAt, debt: item.initialDebt, id: item.id,
        }));

        paymentOrder = response.debt.map((item) => item.payments.map((item2) => ({
          createdAt: item2.createdAt, payment: item2.amount, id: item.id,
        })));
        setAllDebtPayments([...paymentOrder.flat(), ...debtOrder]
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));
        if (response.debt && response.debt.length > 0) {
          setLastCreditTaken(debtOrder
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))[0]);
        }
        // aca poner los payments hechos de las debts ---
        const paymentObject = {};
        response.debt.forEach((debt) => {
          paymentObject[debt.id] = debt.payments;
        });
        dispatch(setDebtPaymentObj(paymentObject));
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    getAllDebtFromCustomer();
  }, []);

  return (
    <Page
      title="Deuda"
    >
      <CustomContainer
        maxWidth="md"
        nopadding="true"
      >
        <Box
          mb={2}
          flex={1}
          display='flex'
          flexDirection='column'
        >
          <Header />
          <CreditSaleDetailTable allDebtPayments={allDebtPayments} />
        </Box>
        <CreditSalePayment lastCreditTaken={lastCreditTaken} />
      </CustomContainer>
    </Page>
  );
}

export default CreditSaleDetailView;

import {
  Box, ClickAwayListener, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
} from 'react-feather';
import { useSelector } from 'react-redux';
import CustomButton from '../../../components/Material/CustomButton';
import CustomTitleTypography from '../../../components/Material/CustomTitlesTypography';
import thousandSeparator from '../../../utils/thousandSeparator';
import useStyles from '../styles';

function CreditSalePayment({ orderInfo }) {
  const history = useHistory();
  const classes = useStyles();
  const { debtId } = useParams();

  const [openDrawer, setOpenDrawer] = useState(false);

  const { debtPaymentObj } = useSelector((state) => state.customer);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleGoPay = () => {
    history.push('/store-options/credit-sale/payments');
  };

  return (
    <ClickAwayListener
      onClickAway={() => setOpenDrawer(false)}
    >
      {/* {console.log('orderInfo', orderInfo)} */}
      <Box
        paddingBottom='20px'
        boxShadow='0px -3px 3px rgba(0, 0, 0, 0.15)'
        position='absolute'
        bottom='0px'
        width='100%'
      >
        <Box
          className={classes.showOptionsBox}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          minHeight='54px'
        >
          <IconButton
            fontSize='large'
            className={classes.chevronLeftSymbol}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            {openDrawer && <ChevronDownIcon />}
            {!openDrawer && <ChevronUpIcon />}
          </IconButton>
          <Typography
            className={classes.typoStyle}
          >
            Pagos realizados
          </Typography>
          {openDrawer && <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.tableHeaderTableCellLeft}
                >
                  Método de pago
                </TableCell>
                <TableCell
                  className={classes.tableHeaderTableCellRight}
                >
                  Monto pagado
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {debtPaymentObj[debtId].map((pay, index) => (
                <TableRow
                  key={index}
                >
                  <TableCell
                    className={classes.tableCellBodyLeftOrange}
                  >
                    {`Pago en ${pay.type}`}
                  </TableCell>
                  <TableCell
                    className={classes.tableCellBodyRightOrange}
                  >
                    {`$ ${thousandSeparator(pay.amount, '.')}`}
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
          }
        </Box>
        <Box
          padding='20px 24px 0px 24px'
        >
          <Typography
            className={classes.blueTypography}
          >
            {`Subtotal: $ ${thousandSeparator((orderInfo.subTotal && orderInfo.subTotal) || 0, '.')}`}
          </Typography>
          <Typography
            className={classes.blueTypography}
          >
            {`Descuentos: ${orderInfo.discount}`}
          </Typography>
          <CustomTitleTypography
            firstHighlight='Total: '
            secondHighlight={`$ ${thousandSeparator((orderInfo.totalAmount && orderInfo.totalAmount) || 0, '.')}`}
            textAlign='left'
          />
        </Box>
        <Box
          mt={2}
          padding='0px 24px'
        >
          <Box>
            <CustomButton
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              buttoncolor='orange'
              onClick={handleGoPay}
            >
              Ir a pagar
            </CustomButton>
          </Box>
          <Box
            mt={2}
          >
            <CustomButton
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              buttoncolor='blue'
              onClick={handleGoBack}
            >
              Atrás
            </CustomButton>
          </Box>

        </Box>
      </Box>
    </ClickAwayListener>
  );
}

export default CreditSalePayment;

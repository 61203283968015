import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import Page from '../../components/Layout/Page';

import Header from './Components/Header';
import CustomContainer from '../../components/Material/CustomContainer';
import CreditSaleBillPayment from './Components/CreditSaleBillPayment';
import CreditSaleBillDetailTable from './Components/CreditSaleBillDetailTable';
import { getDebtDetail } from '../../requests/api/debts';
import handleApiResponse from '../../utils/handleApiResponse';
import useStyles from './styles';

function CreditSaleBillDetailView() {
  const { debtId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const [allProducts, setAllProducts] = useState([]);
  const [orderInfo, setOrderInfo] = useState({});

  useEffect(() => {
    const getBillDetail = async () => {
      try {
        const response = await getDebtDetail(debtId);
        setAllProducts((
          response.debt
          && response.debt.order
          && response.debt.order.orderProducts) || []);
        // Total price se asume puede tener descuentos por lista de precios,
        // a eso se le van a sumar descuentos por promociones o cupones
        setOrderInfo({
          totalAmount: response.debt.order.totalAmount,
          discount: (Number(response.debt.order.promotionDiscount) || 0)
            + (Number(response.debt.order.couponDiscount) || 0),
          subTotal: response.debt.order.orderProducts
            .reduce((accum, value) => accum + Number(value.totalPrice), 0),
        });
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    getBillDetail();
  }, []);

  return (
    <Page
      title="Pago"
    >
      <CustomContainer
        maxWidth="md"
        nopadding="true"
        className={classes.positionRelative}
      >
        <Box
          mb={2}
          flex={1}
        >
          <Header />
          <CreditSaleBillDetailTable allProducts={allProducts} />
        </Box>
        <CreditSaleBillPayment orderInfo={orderInfo} />
      </CustomContainer>
    </Page>
  );
}

export default CreditSaleBillDetailView;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  blueText: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
    fontSize: theme.fontSizes.bodyText,
  },
  disabledText: {
    color: 'lightgray',
    fontWeight: 700,
    fontSize: theme.fontSizes.bodyText,
  },
  orangeText: {
    color: theme.palette.primary.orange,
    fontWeight: 700,
  },
  marginDivider: {
    margin: '25px 0px',
    border: '1px solid',
    borderColor: theme.palette.primary.blue,
  },
  boldTitle: {
    fontSize: theme.fontSizes.bodyText,
    fontWeight: 700,
    color: theme.palette.primary.blue,
  },
  tableHeaderTableCellLeft: {
    padding: '0px 8px 0px 16px',
    fontSize: theme.fontSizes.tableHeader,
    fontWeight: 700,
    color: theme.palette.primary.blue,
  },
  tableHeaderColor: {
    backgroundColor: '#f1f1f1',
  },
  tableHeaderTableCellRight: {
    padding: '0px 16px 0px 8px',
    textAlign: 'center',
    fontSize: theme.fontSizes.tableHeader,
    color: theme.palette.primary.blue,
  },
  regularText: {
    fontSize: theme.fontSizes.bodyText,
    color: theme.palette.primary.blue,
  },
  tableHeader: {
    backgroundColor: '#f1f1f1',
    height: '40px',
    fontSize: theme.fontSizes.tableHeader,
  },
  tableCellCenter: {
    textAlign: 'center',
  },
  boldBlueNumber: {
    fontSize: '25px',
    color: theme.palette.primary.blue,
    bold: 700,
  },
  minusSymbol: {
    margin: '5px 10px 0px 0px',
    color: theme.palette.primary.orange,
  },
  plusSymbol: {
    margin: '5px 0px 0px 10px',
    color: theme.palette.primary.orange,
  },
  chevronLeftSymbol: {
    color: theme.palette.primary.blue,
  },
  blueButton: {
    backgroundColor: theme.palette.primary.blue,
    fontWeight: 'bold',
    color: 'white',
    marginTop: '8px',
  },
  orangeButton: {
    backgroundColor: theme.palette.primary.orange,
    fontWeight: 'bold',
    color: 'white',
    marginTop: '8px',
  },
  modalBoxStyle: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '20px 25px',
    maxWidth: '380px',
    borderRadius: '12px',
    alignItems: 'center',
  },
}));

export default useStyles;

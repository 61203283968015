/* eslint-disable import/prefer-default-export */
import axios from '../../utils/axios';

const getUserById = async (userId) => {
  const response = await axios.get(`/api/users/${userId}`);

  return response.data;
};

const getUserStoresById = async (userId) => {
  const response = await axios.get(`/api/users/${userId}/stores`);
  return response.data;
};

export {
  getUserById,
  getUserStoresById,
};

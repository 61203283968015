import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  orangeTitle: {
    color: theme.palette.primary.orange,
    fontSize: (props) => (props.fontSize ? props.fontSize : '22px'),
    fontWeight: 700,
  },
  blueTitle: {
    color: theme.palette.primary.blue,
    fontSize: (props) => (props.fontSize ? props.fontSize : '22px'),
    fontWeight: 700,
  },
}));

const CustomTitleTypography = ({
  firstHighlight, secondHighlight, blueFirst = false, textAlign = 'center', ...props
}) => {
  const classes = useStyles(props);

  return (
    <Box
      textAlign={textAlign}
    >
      <Typography
        component="span"
        variant="body1"
        className={blueFirst ? classes.blueTitle : classes.orangeTitle}
      >
        {firstHighlight}
        {' '}
      </Typography>
      <Typography
        component="span"
        variant="body1"
        className={blueFirst ? classes.orangeTitle : classes.blueTitle}
      >
        {secondHighlight}
      </Typography>
    </Box>
  );
};

export default CustomTitleTypography;

export const RESET_RETURN_BILL = '@returns/reset-return-machine';
export const SET_RETURN_BILL = '@returns/set-RDC-machine';
export const RESET_PRODUCTS_TO_RETURN = '@returns/reset-return-machine';
export const SET_PRODUCTS_TO_RETURN = '@returns/set-return-machine';
export const RESET_RETURN_FILTERS = '@returns/reset-return-filters';

export const SET_RETURNS_DATE_FILTER = '@returns/set-returns-date-filter';
export const SET_RETURNS_BILL_FILTER = '@returns/set-returns-bill-filter';

export function resetReturnsBill() {
  return {
    type: RESET_RETURN_BILL,
  };
}

export function setReturnsBill(payload) {
  return {
    type: SET_RETURN_BILL,
    payload,
  };
}

export function resetProductsToReturn() {
  return {
    type: RESET_PRODUCTS_TO_RETURN,
  };
}

export function setProductsToReturn(payload) {
  return {
    type: SET_PRODUCTS_TO_RETURN,
    payload,
  };
}

export function resetReturnsFilters() {
  return {
    type: RESET_RETURN_FILTERS,
  };
}

export function setReturnsDateFilter(payload) {
  return {
    type: SET_RETURNS_DATE_FILTER,
    payload,
  };
}

export function setReturnBillFilter(payload) {
  return {
    type: SET_RETURNS_BILL_FILTER,
    payload,
  };
}

import React from 'react';
import {
  Box,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Page from '../../components/Layout/Page';
import Header from './Header';
import DisplayItems from './DisplayItems';
import CustomContainer from '../../components/Material/CustomContainer';
import CustomButton from '../../components/Material/CustomButton';
import { calculateSubTotal, goPayCart } from '../../utils/checkoutFlowFunctions';
import { VOUCHER } from '../../utils/const/bill';

function SaleView() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { productCart } = useSelector((state) => state.cart);
  const { id: sellerId, clientId } = useSelector((state) => state.account.user);
  const {
    moduleInformation, selectedMachine, selectedStore, createdOrderId,
  } = useSelector((state) => state.session);
  const { customer } = useSelector((state) => state.customer);

  const handleGoCart = () => {
    if (productCart && Object.keys(productCart).length > 0) {
      history.push('/cart');
    } else {
      enqueueSnackbar('No tienes productos en el carrito', {
        variant: 'error',
      });
    }
  };

  const handlePay = async () => {
    const { subTotal, isCustomPrice } = calculateSubTotal(productCart);
    await goPayCart({
      productCart,
      createdOrderId,
      dispatch,
      clientId,
      customer,
      sellerId,
      selectedMachine,
      selectedStore,
      subTotal,
      coupon: null,
      moduleInformation,
      billingType: VOUCHER,
      history,
      enqueueSnackbar,
      individualCustomPrice: isCustomPrice,
    });
  };

  return (
    <Page
      title="Venta Cliente"
    >
      <CustomContainer
        nopadding="true"
        maxWidth="md"
      >
        <Box
          padding='0px 24px'
        >
          <Header />
        </Box>
        <DisplayItems />
        <Box
          display='flex'
          mt={3}
          justifyContent='center'
          width='100%'
        >
          <Box
            padding='0px 24px'
            width='100%'
          >
            < Box
              mb={2}
            >
              <CustomButton
                type="submit"
                variant="contained"
                color='primary'
                onClick={handleGoCart}
                disabled={!productCart}
                buttoncolor='blue'
              >
                Ver Carrito
              </CustomButton>
            </Box>
            {!moduleInformation.rdcPayment && <Box>
              <CustomButton
                type="submit"
                variant="contained"
                color='primary'
                onClick={handlePay}
                disabled={!productCart}
                buttoncolor='orange'
              >
                Ir a pagar
              </CustomButton>
            </Box>}

          </Box>
        </Box>
      </CustomContainer>
    </Page>
  );
}

export default SaleView;

import React from 'react';
import {
  Box,
  CircularProgress,
} from '@material-ui/core';
import Page from '../Layout/Page';

import useStyles from './styles';
import CustomContainer from '../Material/CustomContainer';
import CustomTitleTypography from '../Material/CustomTitlesTypography';

function CustomizedSplashScreenView({
  tabTitle,
  firstTitle,
  secondTitle,
}) {
  const classes = useStyles();

  return (
    <Page
      title={tabTitle}
    >
      <CustomContainer maxWidth="md">
        <Box
          mb={6}
          textAlign='center'
        >
          <CustomTitleTypography
            firstHighlight={firstTitle}
            secondHighlight={secondTitle}
          />
        </Box>
        <Box
          display='flex'
          justifyContent='center'
        >
          <Box
            position='relative'

          >
            <CircularProgress
              variant="determinate"
              className={classes.bottom}
              size={150}
              thickness={6}
              value={100}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              className={classes.top}
              classes={{
                circle: classes.circle,
              }}
              size={150}
              thickness={6}
            />
          </Box>
        </Box>
      </CustomContainer>
    </Page>
  );
}

export default CustomizedSplashScreenView;

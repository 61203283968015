/* eslint-disable default-param-last */
/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import {
  RESET_CUSTOMER,
  ADD_CUSTOMER,
  EDIT_CUSTOMER,
  ADD_CUSTOMER_RUT,
  RESET_SELECTED_CUSTOMER_DEBT,
  SET_SELECTED_CUSTOMER_DEBT,
  RESET_DEBTS_ARRAY,
  UPSERT_DEBTS_ARRAY,
  RESET_DEBT_PAYMENT_OBJ,
  SET_DEBT_PAYMENT_OBJ,
} from '../actions/customerActions';

const initialState = {
  customer: null,
  rut: null,
  selectedCustomerDebt: null,
  debtsArray: null,
  debtPaymentObj: null,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CUSTOMER: {
      return produce(state, (draft) => {
        draft.customer = null;
      });
    }

    case ADD_CUSTOMER: {
      return produce(state, (draft) => {
        draft.customer = action.payload;
      });
    }

    case EDIT_CUSTOMER: {
      return produce(state, (draft) => {
        const { customer } = state;
        draft.customer = { ...customer, ...action.payload };
      });
    }

    case ADD_CUSTOMER_RUT: {
      return {
        rut: action.payload,
      };
    }

    case RESET_SELECTED_CUSTOMER_DEBT: {
      return produce(state, (draft) => {
        draft.selectedCustomerDebt = null;
      });
    }

    case SET_SELECTED_CUSTOMER_DEBT: {
      return produce(state, (draft) => {
        draft.selectedCustomerDebt = action.payload;
      });
    }

    case RESET_DEBTS_ARRAY: {
      return produce(state, (draft) => {
        draft.debtsArray = null;
      });
    }

    case UPSERT_DEBTS_ARRAY: {
      return produce(state, (draft) => {
        draft.debtsArray = action.payload;
      });
    }

    case RESET_DEBT_PAYMENT_OBJ: {
      return produce(state, (draft) => {
        draft.debtPaymentObj = null;
      });
    }

    case SET_DEBT_PAYMENT_OBJ: {
      return produce(state, (draft) => {
        draft.debtPaymentObj = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default customerReducer;

import React from 'react';
import ErrorView from '../../components/Error';

function ErrorReturnsFinalView() {
  return (
    <ErrorView
      tabTitle={'Error Devolución'}
      title={'Error'}
      subTitle={'No se pudo generar la nota crédito'}
    />
  );
}

export default ErrorReturnsFinalView;

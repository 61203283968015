import React, { useState } from 'react';
import {
  Box,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Layout/Page';
import Header from './Components/Header';

import DisplayItems from './Components/DisplayItems';
import InsertProduct from './Components/InsertProduct';
import { updateStock } from '../../requests/api/products';
import handleApiResponse from '../../utils/handleApiResponse';
import { resetAllProductStock, resetProductStock } from '../../actions/productActions';
import { STOCK } from '../../utils/const/reasonStockChange';
import CustomTextField from '../../components/Material/CustomTextfieldV2';
import CustomContainer from '../../components/Material/CustomContainer';
import CustomButton from '../../components/Material/CustomButton';

function StockView() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [billNumber, setBillNumber] = useState('');

  const { addStock } = useSelector((state) => state.product);
  const { selectedStore } = useSelector((state) => state.session);

  const handleSubmitStock = async () => {
    if (!billNumber || billNumber.trim() === '') {
      enqueueSnackbar('Se necesita ingresar un número de factura', {
        variant: 'error',
      });
      return;
    }
    const submittedStock = Object.keys(addStock).map((elem) => ({
      storeId: selectedStore.id,
      productId: elem,
      quantity: addStock[elem].toAdd,
      reason: STOCK,
      unitsIn: addStock[elem].toAdd,
      unitsOut: 0,
      billNumber,
      cost: addStock[elem].cost,
    })).filter((elem) => addStock[elem.productId].toAdd !== 0);

    try {
      await updateStock(submittedStock);
      dispatch(resetAllProductStock());
      dispatch(resetProductStock());
      setBillNumber('');
      enqueueSnackbar('Factura ingresada correctamente', {
        variant: 'success',
      });
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  return (
    <Page
      title="Ingresar stock"
    >
      <CustomContainer
        nopadding="true"
        maxWidth="md"
      >
        <Box
          padding='0px 24px'
        >
          <Header />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          padding='0px 24px'
          mb={3}
        >
          <CustomTextField
            fullWidth
            color="secondary"
            name="password"
            variant="outlined"
            placeholder='Número de factura'
            value={billNumber}
            onChange={(e) => setBillNumber(e.target.value)}
          />
        </Box>
        <DisplayItems />
        <Box
          width='100%'
          padding='16px 24px 0px 24px'
          borderTop='3px solid'
          borderColor='#f1f1f1'
        >
          <InsertProduct />
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            color='primary'
            onClick={handleSubmitStock}
          >
            Terminar
          </CustomButton>
        </Box>
      </CustomContainer>
    </Page>
  );
}

export default StockView;

import React, { useEffect, useState } from 'react';
import {
  Box, InputLabel, MenuItem, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import paymentMethods from '../../../utils/const/paymentMethods';
import useStyles from '../styles';
import thousandSeparator from '../../../utils/thousandSeparator';
import CustomTextField from '../../../components/Material/CustomTextfieldV2';
import CustomButton from '../../../components/Material/CustomButton';
import CustomTitleTypography from '../../../components/Material/CustomTitlesTypography';
import { postNewPayment } from '../../../requests/api/payments';
import { setSelectedCustomerDebt, upsertDebtsArray } from '../../../actions/customerActions';
import handleApiResponse from '../../../utils/handleApiResponse';
import { PAYMENT_APPROVED } from '../../../utils/const/status';

function Payment({
  setIsLoadingBill,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    moduleInformation,
  } = useSelector((state) => state.session);
  const { selectedCustomerDebt, debtsArray } = useSelector((state) => state.customer);
  const { clientId } = useSelector((state) => state.account.user);

  const [selectedPayment, setSelectedPayment] = useState('');
  const [amountToPay, setAmountToPay] = useState(0);
  const [paymentIndex, setPaymentIndex] = useState(0);

  const [openSelect, setOpenSelect] = useState(false);

  const handlePay = async () => {
    const formatedAmountPayed = Number(selectedCustomerDebt.totalPayedAmount);
    const debtLeftToPay = selectedCustomerDebt.totalDebt
      - (formatedAmountPayed || 0);

    // Monto no debe ser mayor de lo que queda por pagar y debe ser mayor a 0
    if ((Number(amountToPay) < 0)
      || (Number(amountToPay) > Number(debtLeftToPay))) {
      enqueueSnackbar('Monto a pagar debe ser mayor a 0 y menor al monto restante de pago', {
        variant: 'error',
      });
      setAmountToPay(debtLeftToPay);
      return;
    }

    const auxDebtsArray = (debtsArray && [...debtsArray]) || [];

    try {
      let newPayment = null;
      if (!moduleInformation.rdcPayment) {
        const { newPayment: auxPayment } = await postNewPayment({
          clientId,
          amount: Number(amountToPay),
          status: PAYMENT_APPROVED,
          orderId: null, // corresponde a mas de una deuda
          type: selectedPayment,
          depositId: '',
          errorMessage: '',
          authCode: '',
          customerId: selectedCustomerDebt.id,
        });

        newPayment = auxPayment.payment;
      } else {
        // eslint-disable-next-line no-console
        console.log('check RDC Payment, not implemented yet');
        throw new Error('RDC Payement not implemented yet');
      }

      if (debtLeftToPay > amountToPay) {
        // agregar o crear el paymentCart
        if (auxDebtsArray.length > 0) {
          auxDebtsArray[paymentIndex] = {
            ...auxDebtsArray[paymentIndex],
            amount: Number(newPayment.amount),
            status: newPayment.status,
            type: newPayment.type,
          };
          dispatch(upsertDebtsArray(auxDebtsArray));
        } else {
          auxDebtsArray[paymentIndex] = {
            amount: Number(newPayment.amount),
            status: newPayment.status,
            type: newPayment.type,
          };
          dispatch(upsertDebtsArray(auxDebtsArray));
        }
        const totalPaid = (formatedAmountPayed || 0) + Number(amountToPay);
        dispatch(setSelectedCustomerDebt({
          ...selectedCustomerDebt,
          totalPayedAmount: totalPaid,
        }));
        setPaymentIndex(paymentIndex + 1);
        setAmountToPay(selectedCustomerDebt.totalDebt - totalPaid);
        return;
      }
      auxDebtsArray[paymentIndex] = {
        amount: Number(newPayment.amount),
        status: newPayment.status,
        type: newPayment.type,
      };
    } catch (err) {
      history.push({
        pathname: '/cart/error',
        state: { errorMessage: 'Hubo problemas con uno de los pagos' },
      });
      handleApiResponse(enqueueSnackbar, err, false);
      return;
    }

    // All payments done
    if (moduleInformation.eBilling) {
      setIsLoadingBill(true);
    }

    if (auxDebtsArray.every((item) => item.status === PAYMENT_APPROVED)) {
      if (!moduleInformation.eBilling) {
        enqueueSnackbar('Tu pago de deuda se ha realizado con éxito', {
          variant: 'success',
        });
        history.push('/store-options');
      } else {
        history.push('/cart/success');
      }
    } else {
      history.push({
        pathname: '/cart/error',
        state: { errorMessage: 'No están aprobados todos los pagos' },
      });
    }
  };

  const handleBack = () => {
    if (debtsArray && debtsArray.length > 0) {
      history.push('/store-options/credit-sale');
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    // Assuming always sequential payments
    let relevantIndex = 0;
    let indexBoolean = false;

    if (debtsArray) {
      for (let i = 0; i < debtsArray.length; i += 1) {
        if (debtsArray[i].status !== PAYMENT_APPROVED) {
          indexBoolean = true;
        }
        if (!indexBoolean) {
          relevantIndex += 1;
        }
      }
      setPaymentIndex(relevantIndex);
      setSelectedPayment(paymentMethods[0].type);
      setAmountToPay(selectedCustomerDebt.totalDebt - (selectedCustomerDebt.totalPayedAmount || 0));
    } else {
      setPaymentIndex(0);
      setSelectedPayment(paymentMethods[0].type);
      setAmountToPay(selectedCustomerDebt.totalDebt - (selectedCustomerDebt.totalPayedAmount || 0));
    }
  }, []);

  return (
    <Box
      display='flex'
      flex='1'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-evenly'
        flex={1}
        width='100%'
      >
        {/* Remaining debt */}
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <Typography
            variant='h4'
            className={classes.blueText}
          >
            Deuda restante
          </Typography>
          <Typography
            variant='h2'
            className={classes.orangeText}
          >
            {`$ ${thousandSeparator((selectedCustomerDebt.totalDebt - (selectedCustomerDebt.totalPayedAmount || 0)) || 0, '.')}`}
          </Typography>
          {(selectedCustomerDebt.totalDebt - (selectedCustomerDebt.totalPayedAmount || 0)) !== 0
            && <CustomTitleTypography
              firstHighlight='Pagado:'
              secondHighlight={`$ ${thousandSeparator(selectedCustomerDebt.totalPayedAmount || 0, '.')}`}
              blueFirst
              fontSize='18px'
            />

          }
        </Box>
        {/* TextFields */}
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <Box
            width='100%'
            maxWidth='400px'
            display='flex'
            flexDirection='column'
            mt={2}
            mb={2}
          >
            <InputLabel className={classes.inputLabel}>Total a Pagar</InputLabel>
            <CustomTextField
              color="secondary"
              onChange={(e) => setAmountToPay(e.target.value)}
              value={amountToPay}
              variant="outlined"
              fullWidth
              placeholder='1500'
            />
          </Box>
          <Box
            width='100%'
            maxWidth='400px'
            display='flex'
            flexDirection='column'
            mt={2}
            mb={2}
          >
            <InputLabel className={classes.inputLabel}>Métodos de pago</InputLabel>
            <CustomTextField
              color="secondary"
              onChange={(e) => setSelectedPayment(e.target.value)}
              value={selectedPayment}
              select
              variant="outlined"
              fullWidth
              onClick={() => setOpenSelect(!openSelect)}
              SelectProps={{
                open: openSelect,
              }}
              className={classes.marginLeft}
            >
              {paymentMethods.map((option, index) => (
                <MenuItem key={index} value={option.type}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomTextField>
          </Box>
        </Box>
      </Box>
      {/*  Buttons */}
      <Box
        width='100%'
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Box
          width='100%'
          maxWidth='400px'
          mt={2}
        >
          <CustomButton
            type="submit"
            size="large"
            variant="contained"
            color='primary'
            onClick={handlePay}
            buttoncolor='blue'
          >
            Pagar
          </CustomButton>
        </Box>
        <Box
          width='100%'
          maxWidth='400px'
          mt={2}
        >
          <CustomButton
            type="submit"
            size="large"
            variant="contained"
            color='primary'
            onClick={handleBack}
          >
            {debtsArray && debtsArray.length > 0 ? 'Guardar Deuda' : 'Atrás'}
          </CustomButton>
        </Box>
      </Box>
    </Box >
  );
}

export default Payment;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useStyles from '../styles';
import CustomTextField from '../../../components/Material/CustomTextfieldV2';
import CustomRadio from '../../../components/Material/CustomRadioButton';
import handleApiResponse from '../../../utils/handleApiResponse';
import { MERMA, OTRO } from '../../../utils/const/returns';
import { postReturn } from '../../../requests/api/returns';
import thousandSeparator from '../../../utils/thousandSeparator';
import CustomButton from '../../../components/Material/CustomButton';

function ReturnsTotal({
  setIsLoading,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [reasonToReturn, setReasonToReturn] = useState(MERMA);
  const [descriptionOfReturn, setDescriptionOfReturn] = useState('');
  const [subtotal, setSubtotal] = useState(0);

  const { clientId } = useSelector((state) => state.account.user);
  const modules = useSelector((s) => s.session.moduleInformation);
  const { productsToReturn, returnsBill } = useSelector((state) => state.returns);

  const handleSubmitButton = async () => {
    if ((reasonToReturn === OTRO) && (descriptionOfReturn.trim() === '')) {
      enqueueSnackbar('Necesitas entregar una razon para la devolución', {
        variant: 'error',
      });
      return;
    }

    if (productsToReturn.reduce((accum, item) => accum + item.quantityToReturn, 0) === 0) {
      history.replace('/store-options');
      return;
    }

    // Simular splash screen - reubicar despues
    setIsLoading(true);

    try {
      await postReturn({
        reason: reasonToReturn,
        description: descriptionOfReturn,
        returnProducts: productsToReturn,
        clientId,
        code: returnsBill.code,
      });
      setIsLoading(false);
      if (!modules.eBilling) {
        enqueueSnackbar('Devolución realizada con éxito', {
          variant: 'success',
        });
        setTimeout(() => {
          history.push('/store-options');
        }, 800);
        return;
      }
      history.push('/store-options/returns/success');
    } catch (err) {
      setIsLoading(false);
      handleApiResponse(enqueueSnackbar, err, false);
      history.push({
        pathname: '/store-options/returns/error',
        state: {
          errorMessage: err.message,
        },
      });
    }
  };

  const calculateSubTotal = (cart) => {
    const auxSubTotal = cart
      .reduce((accum, item) => accum + item.unitPrice * item.quantityToReturn, 0);

    return auxSubTotal;
  };

  useEffect(() => {
    if (productsToReturn) {
      const auxSubTotal = calculateSubTotal(productsToReturn);
      setSubtotal(auxSubTotal);
    }
  }, [productsToReturn]);

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      alignItems='center'
    >
      <Divider
        className={classes.dividerStyle}
      />
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        padding='0px 24px'
      >
        <Typography
          variant="h2"
          className={classes.blueText}
        >
          Motivo
        </Typography>
        <FormControl>
          <FormLabel id="radio-buttons-returns-reason" />
          <RadioGroup
            aria-labelledby="radio-buttons-returns-reason"
            name="radio-buttons-group"
            row
            onChange={(e) => setReasonToReturn(e.target.value)}
            value={reasonToReturn}
          >
            <FormControlLabel classes={{ label: classes.radioButtonLabel }} value={MERMA} control={<CustomRadio />} label="Merma" />
            <FormControlLabel classes={{ label: classes.radioButtonLabel }} value={OTRO} control={<CustomRadio />} label="Otro" />
          </RadioGroup>
        </FormControl>
        <CustomTextField
          fullWidth
          color="secondary"
          placeholder="El producto venía dañado"
          onChange={(e) => setDescriptionOfReturn(e.target.value)}
          value={descriptionOfReturn}
          disabled={reasonToReturn !== 'otro'}
          variant="outlined"
        />
      </Box>
      <Box
        display='flex'
        mt={2}
        width='100%'
        padding='0px 24px'
        mb={2}
      >
        <Typography
          variant='h4'
          className={classes.orangeText}
        >
          {`Total: $ ${thousandSeparator(subtotal, '.')}`}
        </Typography>
      </Box>
      <Box
        width='100%'
        padding='0px 24px'
      >
      <CustomButton
        type="submit"
        variant="contained"
        color='secondary'
        buttoncolor='blue'
        onClick={handleSubmitButton}
      >
        {productsToReturn
        && productsToReturn.reduce((accum, item) => accum + item.quantityToReturn, 0) !== 0
          ? 'Generar'
          : 'Volver Opciones de Tienda'
        }
      </CustomButton>
      </Box>

    </Box>
  );
}

export default ReturnsTotal;

/* eslint-disable import/prefer-default-export */
import axios from '../../utils/axios';

const getDebtsPerPage = async (debtsInfo) => {
  const allParams = new URLSearchParams(debtsInfo);
  const response = await axios.get(`/api/debts?${allParams}`);
  return response.data;
};

const getCustomerDebt = async (customerId) => {
  const response = await axios.get(`/api/debt/customer/${customerId}`);
  return response.data;
};

const getDebtDetail = async (debtId) => {
  const response = await axios.get(`/api/debt/${debtId}`);
  return response.data;
};

const postNewDebt = async (postObject) => {
  const response = await axios.post('/api/debt/create', postObject);
  return response.data;
};

export {
  getDebtsPerPage,
  getCustomerDebt,
  getDebtDetail,
  postNewDebt,
};

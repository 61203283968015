import React, { useEffect, useState } from 'react';
import {
  Box,
  SvgIcon,
} from '@material-ui/core';

import { validate, clean } from 'rut.js';

import {
  ChevronLeft as ChevronLeftIcon,
} from 'react-feather';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import CustomTextField from '../../components/Material/CustomTextfield';
import { getCustomerByRut } from '../../requests/api/customers';
import { addCustomer, resetCustomer, addCustomerRut } from '../../actions/customerActions';
import {
  resetCart, resetPaymentsCart, resetReservedStock, resetTotalCartAmount,
} from '../../actions/cartActions';
import CustomTitleTypography from '../../components/Material/CustomTitlesTypography';
import CustomButton from '../../components/Material/CustomButton';

function ClientTypeSelector() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((s) => s.account.user);
  const [rut, setRut] = useState([]);
  const [errorRut, setErrorRut] = useState('');

  const handleClientLogin = async () => {
    if (!validate(rut)) {
      setErrorRut('RUT inválido');
      return;
    }

    try {
      const response = await getCustomerByRut(clean(rut), user.clientId);
      const auxCustomer = response.customer;
      dispatch(addCustomer(auxCustomer));
      history.push('/sale');
    } catch (err) {
      if (err.response.status === 404) {
        dispatch(addCustomerRut(rut));
        history.push('/store-options/create-client');
      } else {
        setErrorRut('Error al ingresar con rut. Favor intentar opción sin rut.');
      }
    }
  };

  const handleRutChange = (e) => {
    setRut(e.target.value);
    setErrorRut('');
  };

  const handleNoClientLogin = () => {
    dispatch(addCustomer(null));
    history.push('/sale');
  };

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(resetCustomer());
    dispatch(resetCart());
    dispatch(resetPaymentsCart());
    dispatch(resetTotalCartAmount());
    dispatch(resetReservedStock());
  }, []);

  return (
    <Box
      display='flex'
      flexDirection='column'
      flex={1}

    >
      <SvgIcon
        fontSize='large'
        className={classes.blueSVG}
        onClick={handleBack}
      >
        <ChevronLeftIcon />
      </SvgIcon>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        flex={1}
      >
        <CustomTitleTypography
          firstHighlight='Ingresar RUT'
          secondHighlight='del Cliente'
        />
        <Box
          mt={3}
          width='100%'
          maxWidth='500px'
        >
          <CustomTextField
            helperText={errorRut && errorRut}
            error={Boolean(errorRut)}
            fullWidth
            color="secondary"
            placeholder="RUT"
            name="password"
            // onBlur={handleBlur}
            onChange={handleRutChange}
            type="text"
            value={rut}
            variant="outlined"
          />
        </Box>
        <Box
          mt={2}
          width='100%'
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <Box
            mb={2}
            width='100%'
          >
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              color='primary'
              onClick={handleClientLogin}
            >
              Enviar
            </CustomButton>
          </Box>
          <Box
            width='100%'
          >
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              color='primary'
              onClick={handleNoClientLogin}
              buttoncolor='blue'
            >
              Continuar Sin Cliente
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

ClientTypeSelector.propTypes = {

};

ClientTypeSelector.defaultProps = {
};

export default ClientTypeSelector;

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Divider,
  SvgIcon,
} from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from 'react-feather';

import useStyles from '../styles';
import CustomTitleTypography from '../../../components/Material/CustomTitlesTypography';

function Header() {
  const classes = useStyles();
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      mb={2}
      width='100%'
    >
      <Box
        display='flex'
        alignItems='center'
      >
        <SvgIcon
          fontSize='large'
          className={classes.chevronLeftSymbol}
          onClick={handleBack}
        >
          <ChevronLeftIcon/>
        </SvgIcon>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          flex={1}
        >
          <CustomTitleTypography
            firstHighlight='Datos de'
            secondHighlight='Facturación'
            blueFirst={true}
          />
        </Box>
      </Box>
      <Divider className={classes.marginDivider}/>
    </Box>
  );
}

export default Header;

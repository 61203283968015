import React from 'react';
import { Box } from '@material-ui/core';
import Page from '../../components/Layout/Page';
import CreateClientForm from './CreateClient';
import CustomContainer from '../../components/Material/CustomContainer';
import Header from './Header';

function CreateClientView() {
  return (
    <Page
      title="Crear Cliente"
    >
      <CustomContainer maxWidth="md">
        <Header />
        <Box
          display='flex'
          flex={1}
        >
          <CreateClientForm />
        </Box>

      </CustomContainer>
    </Page>
  );
}

export default CreateClientView;

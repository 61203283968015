import { Button, withStyles } from '@material-ui/core';

const CustomButton = withStyles((theme) => ({
  root: {
    backgroundColor: (props) => (props.buttoncolor === 'blue' ? theme.palette.primary.blue : theme.palette.primary.orange),
    fontWeight: 700,
    fontSize: '18px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    textTransform: 'none',
    width: '100%',
  },
}))(Button);

export default CustomButton;

/* eslint-disable default-param-last */
/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import {
  RESET_RETURN_BILL,
  SET_RETURN_BILL,
  RESET_PRODUCTS_TO_RETURN,
  SET_PRODUCTS_TO_RETURN,
  RESET_RETURN_FILTERS,
  SET_RETURNS_DATE_FILTER,
  SET_RETURNS_BILL_FILTER,
} from '../actions/returnsActions';

const initialState = {
  returnsBill: null,
  productsToReturn: null,
  dateFilter: null,
  billNumberFilter: null,
};

const returnsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_RETURN_BILL: {
      return produce(state, (draft) => {
        draft.returnsBill = null;
      });
    }

    case SET_RETURN_BILL: {
      return produce(state, (draft) => {
        draft.returnsBill = action.payload;
      });
    }

    case RESET_PRODUCTS_TO_RETURN: {
      return produce(state, (draft) => {
        draft.productsToReturn = null;
      });
    }

    case SET_PRODUCTS_TO_RETURN: {
      return produce(state, (draft) => {
        draft.productsToReturn = action.payload;
      });
    }

    case RESET_RETURN_FILTERS: {
      return produce(state, (draft) => {
        draft.dateFilter = null;
        draft.billNumberFilter = null;
      });
    }

    case SET_RETURNS_DATE_FILTER: {
      return produce(state, (draft) => {
        draft.dateFilter = action.payload;
      });
    }

    case SET_RETURNS_BILL_FILTER: {
      return produce(state, (draft) => {
        draft.billNumberFilter = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default returnsReducer;

import React, { useEffect, useState } from 'react';
import {
  Box, Button, Typography, SvgIcon,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { CheckCircle as CheckIcon } from 'react-feather';
import useStyles from './styles';
import CustomTextField from '../Material/CustomTextfieldV2';
import { validateEmail } from '../../utils/validator';
import handleApiResponse from '../../utils/handleApiResponse';
import { postSendBillEmail } from '../../requests/api/payments';
import CustomButton from '../Material/CustomButton';
import CustomTitleTypography from '../Material/CustomTitlesTypography';

function Success({
  firstTitle,
  secondTitle,
  documentType,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const { customer } = useSelector((state) => state.customer);
  const { createdOrderId } = useSelector((state) => state.session);

  const handleFinalSale = () => {
    history.push('/store-options');
  };

  const handleOnChangeEmail = (e) => {
    setEmailError(false);
    setEmail(e.target.value);
  };

  const handleOnBlurEmail = () => {
    if (!validateEmail(email)) {
      setEmailError(true);
    }
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }
    try {
      if (!email.length) {
        throw new Error('Por favor, ingrese un correo');
      }
      const response = await postSendBillEmail({ email, orderId: createdOrderId });
      enqueueSnackbar(`${response.message}`, {
        variant: 'success',
      });
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  useEffect(() => {
    if (customer && customer.email) {
      setEmail(customer.email);
    }
  }, []);

  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='column'
    >
      <Box
        display='flex'
        alignItems='center'
        flexDirection='column'
        mb={3}
      >
        <SvgIcon fontSize="small" className={classes.iconContainer}>
          <CheckIcon className={classes.checkIcon} />
        </SvgIcon>
        <Box>
          <CustomTitleTypography
            firstHighlight={firstTitle}
            secondHighlight={secondTitle}
            blueFirst={true}
          />
        </Box>
      </Box>
      <Box
        mb={3}
      >
        <Box
          mb={1}
        >
          <Typography
            component="span"
            variant="body1"
            className={classes.regularBlueText}
          >
            ¿Cómo quieres la
            {' '}
          </Typography>
          <Typography
            component="span"
            variant="body1"
            className={classes.blueText}
          >
            {`${documentType}?`}
          </Typography>
        </Box>
        <CustomButton
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          buttoncolor='blue'
        >
          Imprimir
        </CustomButton>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Box
          mb={1}
        >
          <Typography
            component="span"
            variant="body1"
            className={classes.regularBlueText}
          >
            Enviar por
            {' '}
          </Typography>
          <Typography
            component="span"
            variant="body1"
            className={classes.blueText}
          >
            Email
          </Typography>
        </Box>
        <Box
          display='flex'
        >
          <CustomTextField
            fullWidth
            error={emailError}
            helperText={emailError && 'Correo no válido'}
            value={email}
            onChange={handleOnChangeEmail}
            onBlur={handleOnBlurEmail}
            color="secondary"
            placeholder='usuario@mail.cl'
            variant="outlined"
          />
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            className={classes.sendButton}
          >
            Enviar
          </Button>
        </Box>
        <Box
          mt={2}
        >
          <Typography
            component="span"
            variant="body1"
            className={classes.blueTextUnderlinedPayment}
            onClick={handleFinalSale}
          >
            Volver a opciones de tienda
          </Typography>
        </Box>

        {false && <Box>
          <Typography
            component="span"
            variant="body1"
            className={classes.blueText}
          >
            {documentType}
            {' '}
          </Typography>
          <Typography
            component="span"
            variant="body1"
            className={classes.blueText}
          >
            enviada con éxito
          </Typography>
        </Box>}
      </Box>
    </Box>
  );
}

export default Success;

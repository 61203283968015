import React, { useState, useEffect } from 'react';
import { Box, SvgIcon } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { ChevronLeft as ChevronLeftIcon } from 'react-feather';
import { getMachinesPerPage } from '../../requests/api/machines';
import { resetRDCMachine, setRDCMachine } from '../../actions/sessionActions';
import handleApiResponse from '../../utils/handleApiResponse';
import CustomTitleTypography from '../../components/Material/CustomTitlesTypography';
import CustomButton from '../../components/Material/CustomButton';
import useStyles from './styles';

function MachineSelector() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { clientId } = useSelector((state) => state.account.user);
  const [machines, setMachines] = useState([]);

  const { id: selectedStoreId } = useSelector((state) => state.session.selectedStore);

  const handleMachineSelection = (RDCNumber) => {
    dispatch(setRDCMachine(RDCNumber));
    history.push('/store-options');
  };

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const getAllRDCMachinesfromLocal = async () => {
      try {
        const response = await getMachinesPerPage(selectedStoreId, clientId);
        if (response.machines && response.machines.length > 0) {
          setMachines(response.machines);
        } else {
          handleApiResponse(enqueueSnackbar, { message: 'No se tiene RDC asociados' }, 'warning');
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    getAllRDCMachinesfromLocal();
    dispatch(resetRDCMachine());
  }, []);

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      flex={1}
    >
      <SvgIcon
        fontSize='large'
        className={classes.blueSVG}
        onClick={handleBack}
      >
        <ChevronLeftIcon />
      </SvgIcon>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        flex={1}
      >
        <CustomTitleTypography
          firstHighlight='Seleccionar'
          secondHighlight='POS'
          blueFirst={true}
        />
        <Box
          mt={6}
          width='100%'
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          {machines.map((btn) => (
            <Box
              key={btn.id}
              marginBottom='16px'
              width='100%'
            >
              <CustomButton
                type="submit"
                fullWidth
                color='primary'
                variant="contained"
                buttoncolor='blue'
                onClick={() => handleMachineSelection(btn.id)}
              >
                {btn.machineId}
              </CustomButton>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

MachineSelector.propTypes = {

};

MachineSelector.defaultProps = {
  onSubmitSuccess: () => { },
};

export default MachineSelector;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import {
  Box, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
  Plus as PlusIcon,
  Minus as MinusIcon,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';

import useStyles from '../styles';
import thousandSeparator from '../../../utils/thousandSeparator';
import { setProductsToReturn } from '../../../actions/returnsActions';
import handleApiResponse from '../../../utils/handleApiResponse';

function DisplayItems() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { returnsBill, productsToReturn } = useSelector((state) => state.returns);

  const handleAddOneItem = (positionOnArray, maxReturnQuantity) => {
    // cantidad a aumentar no puede ser mayor a cantidad que esta disponible en la factura
    const { quantityToReturn } = productsToReturn[positionOnArray];

    if (quantityToReturn + 1 <= maxReturnQuantity) {
      const auxNewState = [...productsToReturn];

      auxNewState[positionOnArray] = {
        ...productsToReturn[positionOnArray],
        quantityToReturn: quantityToReturn + 1,
      };

      dispatch(setProductsToReturn(auxNewState));
    } else {
      enqueueSnackbar('No puedes devolver más productos de los que están en la factura o los que ya han sido devueltos', {
        variant: 'error',
      });
    }
  };

  const handleRemoveOneItem = (positionOnArray) => {
    const { quantityToReturn } = productsToReturn[positionOnArray];

    if (quantityToReturn > 0) {
      const auxNewState = [...productsToReturn];

      auxNewState[positionOnArray] = {
        ...productsToReturn[positionOnArray],
        quantityToReturn: quantityToReturn - 1,
      };

      dispatch(setProductsToReturn(auxNewState));
    }
  };

  useEffect(() => {
    const initialInformation = async () => {
      try {
        // const responseReturn = await ge
        const auxProducts = returnsBill.order.orderProducts.map((item) => ({
          id: item.id,
          orderId: item.orderId,
          billingId: returnsBill.id,
          code: returnsBill.code,
          productId: item.productId,
          quantityToReturn: 0,
          quantityOnBill: item.quantity,
          quantityReturned: returnsBill.returns
            && returnsBill.returns[item.productId].quantityReturned,
          unitPrice: item.unitPrice,
          totalPrice: item.totalPrice,
          couponDiscount: item.couponDiscount,
          promotionDiscount: item.promotionDiscount,
          name: item.product.name,
        }));
        dispatch(setProductsToReturn(auxProducts));
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (returnsBill
      && returnsBill.order
      && returnsBill.order.orderProducts) {
      initialInformation();
    } else {
      enqueueSnackbar('No hay información de la factura/boleta', {
        variant: 'error',
      });
    }
  }, []);

  return (
    <PerfectScrollbar >
      <Box
        className={classes.displayItemsWrapper}
      >
        <Table stickyHeader>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell
                className={classes.headerTable}
                colSpan={4}
              >
                <Typography
                  variant='h1'
                  component='span'
                  className={classes.boldOrangeText}
                >
                  {'Boleta N° '}
                </Typography>
                <Typography
                  variant='h1'
                  component='span'
                  className={classes.boldBlueText}
                >
                  {returnsBill.code}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={clsx(
                classes.tableHeaderTableCellLeft,
                classes.tableHeaderMaxWidth,
                classes.tableHeaderColor,
              )}>
                Item
              </TableCell>
              <TableCell className={
                clsx(
                  classes.tableHeaderMaxWidth,
                  classes.regularText,
                  classes.tableHeaderTableCell,
                  classes.tableHeaderColor,
                )}>
                Cantidad
              </TableCell>
              <TableCell className={
                clsx(
                  classes.tableHeaderMaxWidth,
                  classes.regularText,
                  classes.tableHeaderTableCell,
                  classes.tableHeaderColor,
                )}>
                Precio
              </TableCell>
              <TableCell className={
                clsx(
                  classes.tableHeaderMaxWidth,
                  classes.regularText,
                  classes.tableHeaderTableCellRight,
                  classes.tableHeaderColor,
                )}>
                Subtotal
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productsToReturn && productsToReturn.map((product, index) => (
              <TableRow key={product.id}>
                <TableCell className={classes.tableCellBodyLeft}>
                  <Typography
                    variant='h4'
                    className={classes.boldTitle}
                  >
                    {product.name}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCellBody}>
                  <Box
                    display='flex'
                    justifyContent='center'
                  >
                    <SvgIcon
                      className={classes.minusSymbol}
                      onClick={() => handleRemoveOneItem(index)}
                    >
                      <MinusIcon />
                    </SvgIcon>
                    <Box className={classes.boldBlueNumber}>
                      {product.quantityToReturn}
                    </Box>
                    <SvgIcon
                      className={classes.plusSymbol}
                      onClick={() => handleAddOneItem(
                        index,
                        product.quantityOnBill - product.quantityReturned,
                      )}
                    >
                      <PlusIcon />
                    </SvgIcon>
                  </Box>
                </TableCell>
                <TableCell className={classes.tableCellBody}>
                  <Typography
                    variant='body1'
                    component='span'
                    className={classes.blueText}
                  >
                    {`$ ${thousandSeparator(product.unitPrice, '.')}`}
                  </Typography>

                </TableCell>
                <TableCell className={clsx(classes.tableCellCenter, classes.tableCellBodyRight)}>
                  <Box className={classes.blueText}>
                    {`$ ${thousandSeparator(product.unitPrice * product.quantityToReturn, '.')}`}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
  );
}

export default DisplayItems;

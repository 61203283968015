export const RESET_CUSTOMER = '@customer/reset-customer';
export const ADD_CUSTOMER = '@customer/add-customer';
export const EDIT_CUSTOMER = '@customer/edit-customer';
export const ADD_CUSTOMER_RUT = '@customer/add-customer-rut';
export const RESET_SELECTED_CUSTOMER_DEBT = '@customer/reset-selected-customer-debt';
export const SET_SELECTED_CUSTOMER_DEBT = '@customer/set-selected-customer-debt';
export const RESET_DEBTS_ARRAY = '@customer/reset-debts-array';
export const UPSERT_DEBTS_ARRAY = '@customer/upsert-debts-array';
export const RESET_DEBT_PAYMENT_OBJ = '@customer/reset-debt-payment-array';
export const SET_DEBT_PAYMENT_OBJ = '@customer/set-debt-payment-array';

export function resetCustomer() {
  return {
    type: RESET_CUSTOMER,
  };
}

export function addCustomer(payload) {
  return {
    type: ADD_CUSTOMER,
    payload,
  };
}

export function editCustomer(payload) {
  return {
    type: EDIT_CUSTOMER,
    payload,
  };
}

export function addCustomerRut(payload) {
  return {
    type: ADD_CUSTOMER_RUT,
    payload,
  };
}

export function resetSelectedCustomerDebt() {
  return {
    type: RESET_SELECTED_CUSTOMER_DEBT,
  };
}

export function setSelectedCustomerDebt(payload) {
  return {
    type: SET_SELECTED_CUSTOMER_DEBT,
    payload,
  };
}

export function resetDebtsArray() {
  return {
    type: RESET_DEBTS_ARRAY,
  };
}

export function upsertDebtsArray(payload) {
  return {
    type: UPSERT_DEBTS_ARRAY,
    payload,
  };
}

export function resetDebtPaymentObj() {
  return {
    type: RESET_DEBT_PAYMENT_OBJ,
  };
}

export function setDebtPaymentObj(payload) {
  return {
    type: SET_DEBT_PAYMENT_OBJ,
    payload,
  };
}

import React, { useState } from 'react';
import { Box, Modal } from '@material-ui/core';

import InsertProductModal from './InsertProductModal';
import CustomButton from '../../../components/Material/CustomButton';

function InsertProduct() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      mt={1}
      mb={3}
    >
      <CustomButton
        type="submit"
        fullWidth
        variant="contained"
        color='primary'
        onClick={() => setOpenModal(true)}
        buttoncolor='blue'
      >
        Ingresar Producto
      </CustomButton>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <InsertProductModal
          setOpenModal={setOpenModal}
        />
      </Modal>

    </Box>
  );
}

export default InsertProduct;

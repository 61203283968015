import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  RadioGroup,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import { createCustomer } from '../../requests/api/customers';
import useStyles from './styles';
import CustomTextField from '../../components/Material/CustomTextfieldV2';
import handleApiResponse from '../../utils/handleApiResponse';
import { addCustomer } from '../../actions/customerActions';
import CustomButton from '../../components/Material/CustomButton';
import CustomRadio from '../../components/Material/CustomRadioButton';

function CreateClientForm({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const rut = useSelector((s) => s.customer.rut);
  const user = useSelector((s) => s.account.user);

  if (submitSuccess) {
    history.push('/sale');
  }

  return (
    <Formik
      initialValues={{
        rut,
        clientId: user.clientId,
        name: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        birthDate: null,
        gender: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar el nombre'),
        lastname: Yup.string().max(255).required('Debe ingresar el apellido'),
        email: Yup.string().max(255),
        date: Yup.date().nullable(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            rut: values.rut,
            clientId: values.clientId,
            name: values.name,
            lastname: values.lastname,
            email: values.email,
            phoneNumber: values.phoneNumber,
            birthDate: values.birthDate,
            gender: values.gender,
          };

          const response = await createCustomer(data);
          if (response.success) {
            dispatch(addCustomer(response.customer));
            handleApiResponse(enqueueSnackbar, response, true);
            setStatus({ success: true });
            setSubmitting(false);
            setSubmitSuccess(true);
          }
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false, 'error');
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
          history.push('store-options/client-type');
        }
      }}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form
          className={clsx(classes.form, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Box
            display='flex'
            flexDirection='column'
            flex={1}
          >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <InputLabel
                  className={classes.inputLabel}
                >
                  Rut
                </InputLabel>
                <Box
                  width='100%'
                >
                  <CustomTextField
                    fullWidth
                    disabled
                    color="secondary"
                    placeholder="1.111.111-1"
                    name="rut"
                    value={values.rut}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <InputLabel
                  className={classes.inputLabel}
                >
                  Nombres
                </InputLabel>
                <Box
                  width='100%'
                >
                  <CustomTextField
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    color="secondary"
                    placeholder="Juan Andres"
                    name="name"
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <InputLabel
                  className={classes.inputLabel}
                >
                  Apellidos
                </InputLabel>
                <Box
                  width='100%'
                >
                  <CustomTextField
                    helperText={touched.lastname && errors.lastname}
                    error={Boolean(touched.lastname && errors.lastname)}
                    fullWidth
                    color="secondary"
                    placeholder="Perez"
                    name="lastname"
                    onChange={handleChange}
                    type="text"
                    value={values.lastname}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <InputLabel
                  className={classes.inputLabel}
                >
                  Fecha de nacimiento
                </InputLabel>
                <Box
                  width='100%'
                >
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={esLocale}
                  >
                    <KeyboardDatePicker
                      fullWidth
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      placeholder="dd/mm/yyyy"
                      format="dd/MM/yyyy"
                      value={values.birthDate}
                      onChange={(value) => setFieldValue('birthDate', value)}
                      className={classes.datePicker}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  row='true'
                >
                  <FormLabel
                    className={classes.inputLabel}
                  >
                    Género
                  </FormLabel>
                  <RadioGroup
                    row
                    value={values.gender}
                    onChange={(e, value) => {
                      setFieldValue('gender', value);
                    }}
                  >
                    <FormControlLabel
                      classes={{ label: classes.radioButtonLabel }}
                      value='female'
                      control={<CustomRadio />}
                      label='Femenino'
                    />
                    <FormControlLabel
                      classes={{ label: classes.radioButtonLabel }}
                      value='male'
                      control={<CustomRadio />}
                      label='Masculino'
                    />
                    <FormControlLabel
                      classes={{ label: classes.radioButtonLabel }}
                      value='other'
                      control={<CustomRadio />}
                      label='Otro'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <InputLabel
                  className={classes.inputLabel}
                >
                  Email
                </InputLabel>
                <Box
                  width='100%'
                >
                  <CustomTextField
                    fullWidth
                    color="secondary"
                    placeholder="hola@ejemplo.cl"
                    name="email"
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <InputLabel
                  className={classes.inputLabel}
                >
                  Teléfono
                </InputLabel>
                <Box
                  width='100%'
                >
                  <CustomTextField
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    fullWidth
                    color="secondary"
                    placeholder="+56 9 1234 5678"
                    name="phoneNumber"
                    onChange={handleChange}
                    type="text"
                    value={values.phoneNumber}
                    variant="outlined"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <CustomButton
              type="submit"
              variant="contained"
              color='primary'
              disabled={isSubmitting}
              buttoncolor='blue'
            >
              Guardar
            </CustomButton>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default CreateClientForm;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chevronLeftSymbol: {
    color: theme.palette.primary.blue,
  },
  blueTextTitle: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
  },
  blueText: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
    fontSize: '18px',
  },
  orangeText: {
    color: theme.palette.primary.orange,
    fontWeight: 700,
    fontSize: '36px',
  },
  marginDivider: {
    border: '0.5px solid',
    borderColor: theme.palette.primary.blue,
    margin: '10px 0px',
  },
  selectPaymentButton: {
    backgroundColor: '#d9d9d9',
    fontSize: theme.fontSizes.bodyText,
    fontWeight: 700,
    textAlign: 'center',
    border: '3px solid',
    borderColor: theme.palette.primary.blue,
    color: theme.palette.primary.blue,
    marginLeft: '15px',
  },
  inputLabel: {
    fontWeight: 700,
    fontSize: theme.fontSizes.bodyText,
    color: theme.palette.primary.blue,
    marginBottom: '8px',
  },
}));

export default useStyles;

const formatDateTime = (time) => {
  const opciones = {
    year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit',
  };
  const formatedDate = new Date(time)
    .toLocaleDateString('es', opciones);
  return formatedDate;
};

const formatDate = (time) => {
  const opciones = {
    year: 'numeric', month: 'numeric', day: 'numeric',
  };
  const formatedDate = new Date(time)
    .toLocaleDateString('es', opciones);
  return formatedDate;
};

const formatTime = (time) => {
  const opciones = {
    hour: '2-digit', minute: '2-digit', second: '2-digit',
  };
  const formatedTime = new Date(time)
    .toLocaleTimeString('es', opciones);
  return formatedTime;
};

module.exports = {
  formatDateTime,
  formatDate,
  formatTime,
};

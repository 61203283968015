/* eslint-disable default-param-last */
/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import {
  SET_RDC_MACHINE,
  RESET_RDC_MACHINE,
  SET_STORE,
  RESET_STORE,
  RESET_ORDER_ID,
  SET_ORDER_ID,
  RESET_TIMES_ERROR,
  SET_TIMES_ERROR,
  RESET_MODULE,
  SET_MODULE,
} from '../actions/sessionActions';

const initialState = {
  selectedMachine: null,
  selectedStore: null,
  createdOrderId: null,
  timesError: 0,
  moduleInformation: null,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_RDC_MACHINE: {
      return produce(state, (draft) => {
        draft.selectedMachine = null;
      });
    }

    case SET_RDC_MACHINE: {
      return produce(state, (draft) => {
        draft.selectedMachine = action.payload;
      });
    }

    case RESET_STORE: {
      return produce(state, (draft) => {
        draft.selectedStore = null;
      });
    }

    case SET_STORE: {
      return produce(state, (draft) => {
        draft.selectedStore = action.payload;
      });
    }

    case RESET_ORDER_ID: {
      return produce(state, (draft) => {
        draft.createdOrderId = null;
      });
    }

    case SET_ORDER_ID: {
      return produce(state, (draft) => {
        draft.createdOrderId = action.payload;
      });
    }

    case RESET_TIMES_ERROR: {
      return produce(state, (draft) => {
        draft.timesError = 0;
      });
    }

    case SET_TIMES_ERROR: {
      return produce(state, (draft) => {
        draft.timesError = action.payload;
      });
    }

    case RESET_MODULE: {
      return produce(state, (draft) => {
        draft.moduleInformation = null;
      });
    }

    case SET_MODULE: {
      return produce(state, (draft) => {
        draft.moduleInformation = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;

import React, { useState } from 'react';
import {
  Box,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import useStyles from '../styles';
import CustomTextField from '../../../components/Material/CustomTextfieldV2';
import handleApiResponse from '../../../utils/handleApiResponse';
import { getProductByEan } from '../../../requests/api/products';
import { setAllProductStock } from '../../../actions/productActions';
import CustomTitleTypography from '../../../components/Material/CustomTitlesTypography';
import CustomButton from '../../../components/Material/CustomButton';

function InsertProductModal({
  setOpenModal,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [states, setStates] = useState({
    barCode: '',
    quantityToAdd: '',
    cost: '',
  });
  const [error, setError] = useState({
    barCode: false,
    quantityToAdd: false,
    cost: false,
  });

  const [foundProduct, setFoundProduct] = useState(null);
  const [isValidEan, setIsValidEan] = useState(false);

  const { clientId } = useSelector((state) => state.account.user);
  const { allProducts } = useSelector((state) => state.product);

  const handleBarCodeChange = (e) => {
    setStates((prevState) => ({ ...prevState, barCode: e.target.value }));
    setError((prevState) => ({ ...prevState, barCode: false }));
  };

  const handleQuantityChange = (e) => {
    setStates((prevState) => ({ ...prevState, quantityToAdd: e.target.value }));
    setError((prevState) => ({ ...prevState, quantityToAdd: false }));
  };

  const handleCostChange = (e) => {
    setStates((prevState) => ({ ...prevState, cost: e.target.value }));
    setError((prevState) => ({ ...prevState, cost: false }));
  };

  const handleBlur = (source) => {
    if (states[source] === '') setError((prevState) => ({ ...prevState, [source]: true }));
  };

  const handleSubmitSearchEan = async () => {
    if (allProducts && allProducts.some((item) => item.ean === states.barCode)) {
      enqueueSnackbar('Ya has agregado este EAN a tu factura', {
        variant: 'error',
      });
    } else {
      try {
        const response = await getProductByEan({ ean: states.barCode, clientId });
        const { searchedProduct } = response;
        setFoundProduct({
          id: searchedProduct.id,
          name: searchedProduct.name,
          sku: searchedProduct.sku,
          ean: searchedProduct.ean,
        });
        setIsValidEan(true);
        setStates((prevState) => ({ ...prevState, cost: searchedProduct.cost }));
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    }
  };

  const handleSubmitEnterStock = async () => {
    if (states.barCode && Number(states.quantityToAdd) > 0 && Number(states.cost) > 0) {
      dispatch(setAllProductStock({
        id: foundProduct.id,
        name: foundProduct.name,
        sku: foundProduct.sku,
        ean: foundProduct.ean,
        quantityToAdd: Number(states.quantityToAdd),
        unitCost: states.cost,
      }));
      setOpenModal(false);
    } else {
      enqueueSnackbar('Chequear nuevamente los campos ingresados', {
        variant: 'error',
      });
    }
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      minHeight='100%'
      padding='0px 24px'
    >
      <Box
        className={classes.modalBoxStyle}
      >
        <CustomTitleTypography
          firstHighlight='Ingresar'
          secondHighlight='Stock'
        />
        <Box
          width='100%'
          mt={2}
        >
          <Typography
            variant='body1'
            component='span'
            className={classes.blueText}
          >
            Código de Barra
          </Typography>
          <CustomTextField
            fullWidth
            error={error.barCode}
            onBlur={() => handleBlur('barCode')}
            onChange={handleBarCodeChange}
            required
            value={states.barCode}
            variant="outlined"
          />
        </Box>
        <Box
          width='100%'
          mt={2}
        >
          <Typography
            variant='body1'
            component='span'
            className={isValidEan ? classes.blueText : classes.disabledText}
          >
            Cantidad
          </Typography>
          <CustomTextField
            fullWidth
            disabled={!isValidEan}
            error={error.quantityToAdd}
            onBlur={() => handleBlur('quantityToAdd')}
            onChange={handleQuantityChange}
            required
            value={states.quantityToAdd}
            variant="outlined"
          />
        </Box>
        <Box
          width='100%'
          mt={2}
        >
          <Typography
            variant='body1'
            component='span'
            className={isValidEan ? classes.blueText : classes.disabledText}
          >
            Costo
          </Typography>
          <CustomTextField
            fullWidth
            disabled={!isValidEan}
            error={error.cost}
            onBlur={() => handleBlur('cost')}
            onChange={handleCostChange}
            required
            InputProps={{
              startAdornment: <InputAdornment position='start'>$</InputAdornment>,
            }}
            value={states.cost}
            variant="outlined"
          />
        </Box>
        <Box
          width='100%'
        >
          <Box
            mt={2}
          >
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              color='primary'
              onClick={isValidEan ? handleSubmitEnterStock : handleSubmitSearchEan}
            >
              {isValidEan ? 'Ingresar' : 'Buscar por EAN'}
            </CustomButton>
          </Box>
          <Box
            mt={2}
          >
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              color='primary'
              onClick={() => setOpenModal(false)}
              buttoncolor='blue'
            >
              Cancelar
            </CustomButton>
          </Box>
        </Box>
      </Box>

    </Box>
  );
}

export default InsertProductModal;

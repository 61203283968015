// import React from 'react';

const handleApiResponse = (
  enqueueSnackbar,
  response,
  successSnackbar,
  variant = null,
  action = null,
) => {
  if (successSnackbar) {
    enqueueSnackbar(response.message, {
      variant: variant || 'success',
      action,
    });
  } else {
    const message = (
      response
      && response.response
      && response.response.data
      && response.response.data.errorMessage)
      ? response.response.data.errorMessage : 'Error desconocido';
    enqueueSnackbar((message), {
      variant: variant || 'error',
      action,
    });
  }
};

export default handleApiResponse;

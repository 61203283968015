import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import clsx from 'clsx';
import { ChevronRight as ChevronRightIcon } from 'react-feather';
import useStyles from '../styles';
import thousandSeparator from '../../../utils/thousandSeparator';
import { formatDate } from '../../../utils/date';

function CreditSaleDetailTable({ allDebtPayments }) {
  const classes = useStyles();
  const history = useHistory();

  const handleIndividualBill = (id, createdAt) => {
    history.push({ pathname: `/store-options/credit-sale/bill/${id}`, state: { date: createdAt } });
  };

  return (
    <PerfectScrollbar >
      <Box
        className={classes.displayItemsWrapper}
      >
        <Table stickyHeader>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={clsx(
                classes.tableHeaderTableCellLeft,
                classes.tableHeaderMaxWidth,
                classes.tableHeaderColor,
              )}>
                Fecha
              </TableCell>
              <TableCell className={
                clsx(
                  classes.tableHeaderMaxWidth,
                  classes.regularText,
                  classes.tableHeaderTableCellRight,
                  classes.tableHeaderColor,
                )}>
                Subtotal
              </TableCell>
              <TableCell className={classes.tableHeaderColor} />
            </TableRow>
          </TableHead>
          <TableBody>
            {allDebtPayments && allDebtPayments.map((debt, index) => (
              <TableRow
                key={index}
                onClick={debt.debt && (() => handleIndividualBill(debt.id, debt.createdAt))}
              >
                <TableCell className={classes.tableCellBodyLeft}>
                  <Typography
                    variant='h4'
                    className={classes.tableCellBodyTypography}
                  >
                    {formatDate(debt.createdAt)}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCellBody}>
                  <Box
                    display='flex'
                    justifyContent='center'
                    flexDirection='column'
                  >
                    {debt.debt
                      && <>
                        <Typography
                          className={
                            clsx(classes.tableCellBodyTypographyRight, classes.blueTextTypo)
                          }
                        >
                          Fiado por
                        </Typography>
                        <Typography
                          className={
                            clsx(classes.tableCellBodyTypographyRight, classes.blueTextTypo)}
                        >
                          {`$ ${thousandSeparator(debt.debt, '.')}`}
                        </Typography>
                      </>
                    }
                    {debt.payment
                      && <>
                        <Typography
                          className={
                            clsx(classes.tableCellBodyTypographyRight, classes.orangeTextTypo)
                          }
                        >
                          Abono por
                        </Typography>
                        <Typography
                          className={
                            clsx(classes.tableCellBodyTypographyRight, classes.orangeTextTypo)
                          }
                        >
                          {`$ ${thousandSeparator(debt.payment, '.')}`}
                        </Typography>
                      </>
                    }
                  </Box>
                </TableCell>
                <TableCell className={classes.noPadding}>
                  {debt.debt && < ChevronRightIcon
                    className={classes.chevronRightSymbol}
                  />}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
  );
}

export default CreditSaleDetailTable;

import React from 'react';
import Page from '../../components/Layout/Page';
import MachineSelector from './MachineSelector';

import CustomContainer from '../../components/Material/CustomContainer';

function MachinesView() {
  return (
    <Page
      title="Máquinas RDC"
    >
      <CustomContainer maxWidth="md">
        <MachineSelector />
      </CustomContainer>
    </Page>
  );
}

export default MachinesView;

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Divider,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from 'react-feather';

import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../styles';
import handleApiResponse from '../../../utils/handleApiResponse';
import { cancelOrder } from '../../../requests/api/orders';
import { resetOrderId } from '../../../actions/sessionActions';

function Header() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { createdOrderId } = useSelector((state) => state.session);

  const handleBack = async () => {
    try {
      await cancelOrder({ orderId: createdOrderId });
      dispatch(resetOrderId());
      history.goBack();
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      mb={2}
    >
      <Box
        display='flex'
        alignItems='center'
      >
        <SvgIcon
          fontSize='large'
          className={classes.chevronLeftSymbol}
          onClick={handleBack}
        >
          <ChevronLeftIcon />
        </SvgIcon>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          flex={1}
        >
          <Box>
            <Typography
              variant='h1'
              component='span'
              className={classes.blueTextTitle}
            >
              {'Pago'}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider className={classes.marginDivider} />
    </Box>
  );
}

export default Header;

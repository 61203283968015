const { addReserveStock, setTotalCartAmount } = require('../actions/cartActions');
const { resetOrderId, setOrderId } = require('../actions/sessionActions');
const { cancelOrder, postNewOrder } = require('../requests/api/orders');
const { VOUCHER, FACTURA } = require('./const/bill');
const { ORDER_PENDING } = require('./const/status');
const { default: handleApiResponse } = require('./handleApiResponse');

export const calculateSubTotal = (cart) => {
  const isCustomPrice = cart && Object.keys(cart).some((item) => cart[item].customPrice > 0);
  const auxSubTotal = cart && Object.keys(cart).reduce((accum, item) => {
    const product = cart[item];
    if (product.customPrice) {
      return accum + (product.customPrice * product.quantityInCart);
    }
    if (product.discountPriceListPrice
      && (product.discountPriceListPrice < product.priceListPrice)) {
      return accum + product.discountPriceListPrice * product.quantityInCart;
    }
    return accum + product.priceListPrice * product.quantityInCart;
  }, 0);
  return { subTotal: auxSubTotal, isCustomPrice };
};

export const goPayCart = async ({
  productCart,
  createdOrderId,
  dispatch,
  clientId,
  customer,
  sellerId,
  selectedMachine,
  selectedStore,
  subTotal,
  coupon,
  moduleInformation,
  billingType,
  history,
  enqueueSnackbar,
  applyCustomTotal,
  individualCustomPrice,
}) => {
  // Crear la orden

  if (!productCart || !Object.keys(productCart).length) {
    enqueueSnackbar('No tienes productos en el carrito', {
      variant: 'error',
    });
    return;
  }

  try {
    const auxProducts = Object.keys(productCart).map((item) => ({
      productId: item,
      quantity: productCart[item].quantityInCart,
      priceListId: productCart[item].priceListId,
      priceListPrice: productCart[item].priceListPrice,
      discountPriceListId: productCart[item].discountPriceListId,
      discountPriceListPrice: productCart[item].discountPriceListPrice,
      cost: productCart[item].cost,
      customPrice: productCart[item].customPrice,
    }));
    // Se guardará la orden creada cada vez en session, y se eliminara cada vez que uno vaya
    // a pagar. De esta forma, al cancelar la orden, se podra acceder nuevamente al stock
    // reservado.
    if (createdOrderId) {
      await cancelOrder({ orderId: createdOrderId });
      dispatch(resetOrderId());
    }
    const response = await postNewOrder({
      clientId,
      billingId: null,
      customerId: (customer && customer.id) || null,
      sellerId,
      machineId: selectedMachine || null,
      storeId: selectedStore.id,
      totalAmount: subTotal,
      couponName: (coupon && coupon.name) || null,
      status: ORDER_PENDING,
      billingType: !moduleInformation.rdcPayment ? VOUCHER : billingType,
      products: auxProducts,
      customPrice: applyCustomTotal || individualCustomPrice,
    });

    const { logsStock, order } = response;

    dispatch(setOrderId(order.id));
    dispatch(addReserveStock(logsStock));
    dispatch(setTotalCartAmount(order.totalAmount));
  } catch (err) {
    handleApiResponse(enqueueSnackbar, err, false);
    return;
  }

  if (billingType === FACTURA) {
    history.push('/cart/bill');
  } else {
    history.push('/cart/payments');
  }
};

/* eslint-disable import/prefer-default-export */
import axios from '../../utils/axios';

const getStockPerPage = async ({ storeId, clientId, searchValue }) => {
  const response = await axios.get(`/api/stocks?storeId=${storeId}&clientId=${clientId || 0}&searchValue=${searchValue || ''}`);
  return response.data;
};

const getProductByEan = async ({ ean, clientId }) => {
  const response = await axios.get(`/api/product/ean/${ean}/clientId/${clientId || 0}`);
  return response.data;
};

const getProductsPricesStock = async ({
  storeId, clientId, searchValue, page, limit,
}) => {
  const response = await axios.get(`/api/product/pos?storeId=${storeId}&clientId=${clientId || 0}&searchValue=${searchValue || ''}&page=${page}&limit=${limit}`);
  return response.data;
};

const updateStock = async (stockUpdateInfo) => {
  const response = await axios.post('/api/stock/update', { stocksArray: stockUpdateInfo });
  return response.data;
};

export {
  getStockPerPage,
  getProductByEan,
  getProductsPricesStock,
  updateStock,
};

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  RadioGroup,
  SvgIcon,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Check,
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
  Edit2,
  RotateCcw,
} from 'react-feather';

import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import useStyles from '../styles';
import CustomTextField from '../../../components/Material/CustomTextfieldV2';
import CustomRadio from '../../../components/Material/CustomRadioButton';
import thousandSeparator from '../../../utils/thousandSeparator';
import handleApiResponse from '../../../utils/handleApiResponse';
import { getCouponAuthentication } from '../../../requests/api/coupons';
import { updateCustomPriceCart } from '../../../actions/cartActions';
import { BOLETA, FACTURA } from '../../../utils/const/bill';
import { CUPON_PORCENTAJE } from '../../../utils/const/coupon';
import CustomButton from '../../../components/Material/CustomButton';
import { calculateSubTotal, goPayCart } from '../../../utils/checkoutFlowFunctions';

function TotalsToPay() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [subTotal, setSubTotal] = useState(0);
  const [coupon, setCoupon] = useState(null);
  const [couponName, setCouponName] = useState('');
  const [enableCustomPrice, setEnableCustomPrice] = useState(true);
  const [editingCustomPrice, setEditingCustomPrice] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [billingType, setBillingType] = useState(BOLETA);
  const { id: sellerId, clientId } = useSelector((state) => state.account.user);
  const {
    selectedMachine, selectedStore, createdOrderId, moduleInformation,
  } = useSelector((state) => state.session);
  const { productCart } = useSelector((state) => state.cart);
  const { customer } = useSelector((state) => state.customer);

  const changePrice = useSelector((state) => state.session.moduleInformation.priceChange);
  const [customTotal, setCustomTotal] = useState('');
  const [applyCustomTotal, setApplyCustomTotal] = useState(false);
  const [individualCustomPrice, setIndividualCustomPrice] = useState(false);
  const minHeightCalc = () => {
    if (moduleInformation.couponManagement && moduleInformation.rdcPayment) {
      return '200px';
    }
    return '120px';
  };

  const handleOpenOptions = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleGoToPay = async () => {
    await goPayCart({
      productCart,
      createdOrderId,
      dispatch,
      clientId,
      customer,
      sellerId,
      selectedMachine,
      selectedStore,
      subTotal,
      coupon,
      moduleInformation,
      billingType,
      history,
      enqueueSnackbar,
      applyCustomTotal,
      individualCustomPrice,
    });
  };

  const submitCouponName = async () => {
    // validate cupon
    const arrayCart = Object.keys(productCart).map((item) => ({
      productId: item,
      quantity: productCart[item].quantityInCart,
      price: productCart[item].discountPriceListPrice && (productCart[item].discountPriceListPrice
        < productCart[item].priceListPrice)
        ? productCart[item].discountPriceListPrice
        : productCart[item].priceListPrice,
    }));
    try {
      if (moduleInformation.couponManagement) {
        const response = await getCouponAuthentication({
          couponName,
          clientId,
          storeId: selectedStore.id,
          customerId: (customer && customer.id) || '',
          totalAmount: subTotal,
          productsInCart: JSON.stringify(arrayCart),
        });

        const { coupon: dbCoupon } = response;
        setCoupon({
          name: dbCoupon.name,
          value: dbCoupon.value,
          type: dbCoupon.type,
          maxDiscount: dbCoupon.maxDiscount,
          actualDiscount: dbCoupon.couponDiscount,
        });
        setCouponName('');
      }
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  useEffect(() => {
    setCoupon(null);
  }, []);

  useEffect(() => {
    if (productCart && customTotal <= 0) {
      const auxSubTotal = calculateSubTotal(productCart);
      setSubTotal(auxSubTotal.subTotal);
      setIndividualCustomPrice(auxSubTotal.isCustomPrice);
      setCoupon(null);
    }
  }, [productCart]);

  useEffect(() => {
    if (customTotal > 0) {
      Object.keys(productCart).forEach((productId) => {
        const price = productCart[productId].priceListPrice;
        const resetValue = calculateSubTotal(productCart);
        setSubTotal(resetValue.subTotal);
        setIndividualCustomPrice(resetValue.isCustomPrice);
        const customPrice = Math.floor((customTotal / resetValue) * price, 0);
        dispatch(updateCustomPriceCart({ productId, customPrice }));
      });
      setSubTotal(customTotal);
    }
  }, [applyCustomTotal]);

  useEffect(() => {
    if (customTotal > 0) {
      Object.keys(productCart).forEach((productId) => {
        const price = productCart[productId].priceListPrice;
        const resetValue = calculateSubTotal(productCart);
        setSubTotal(resetValue.subTotal);
        setIndividualCustomPrice(resetValue.isCustomPrice);
        const customPrice = Math.floor((customTotal / resetValue) * price, 0);
        dispatch(updateCustomPriceCart({ productId, customPrice }));
      });
      setSubTotal(customTotal);
    }
  }, [applyCustomTotal]);

  return (
    <ClickAwayListener
      onClickAway={() => setOpenDrawer(false)}
    >
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        position='absolute'
        bottom='0px'
        width='100%'
      >
        <Box
          className={(moduleInformation.couponManagement || moduleInformation.rdcPayment)
            ? classes.showOptionsBox
            : classes.showOptionsBoxNoOptions
          }
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          {(moduleInformation.couponManagement || moduleInformation.rdcPayment) && <Box
            width='100%'
            display='flex'
            justifyContent='center'
            onClick={handleOpenOptions}
          >
            <SvgIcon
              fontSize='large'
              className={classes.chevronLeftSymbol}
            >
              {openDrawer && <ChevronDownIcon />}
              {!openDrawer && <ChevronUpIcon />}
            </SvgIcon>
          </Box>

          }
          {openDrawer && <Box
            display='flex'
            flexDirection='column'
            minHeight={minHeightCalc()}
            width='100%'
            justifyContent='space-between'
            padding='0px 24px 16px 24px'
          >
            {moduleInformation.couponManagement
              && <><Box>
                <Typography
                  variant='h4'
                  className={classes.blueText}
                >
                  Ingresar Cupón de Descuento
                </Typography>
                <Box
                  display='flex'
                  mt={1}
                  mb={1}
                >
                  <CustomTextField
                    color="secondary"
                    placeholder="Código Cupón"
                    onChange={(e) => setCouponName(e.target.value)}
                    type="text"
                    value={couponName}
                    variant="outlined"
                    fullWidth
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    className={clsx(classes.couponButton, classes.minWidthButton)}
                    onClick={submitCouponName}
                  >
                    Enviar
                  </Button>
                </Box>
                {!coupon
                  && <Typography
                    variant='h4'
                    className={classes.regularText}
                  >
                    No hay ningún cupón aplicado
                  </Typography>}
                {coupon
                  && <Typography
                    variant='h4'
                    className={classes.regularText}
                  >
                    {`Cupón aplicado: ${coupon.name}`}
                  </Typography>}
              </Box>
                {moduleInformation.eBilling
                && <Divider className={classes.marginDivider} />}
              </>
            }
            {moduleInformation.eBilling
              && <><Box>
                <Typography
                  variant='h4'
                  className={classes.blueText}
                >
                  Documento
                </Typography>
                <FormControl>
                  <FormLabel id="radio-buttons-type-billing" />
                  <RadioGroup
                    aria-labelledby="radio-buttons-type-billing"
                    name="radio-buttons-group"
                    row
                    onChange={(e) => setBillingType(e.target.value)}
                    value={billingType}
                  >
                    <FormControlLabel classes={{ label: classes.radioButtonLabel }} value={BOLETA} control={<CustomRadio />} label="Boleta" />
                    <FormControlLabel classes={{ label: classes.radioButtonLabel }} value={FACTURA} control={<CustomRadio />} label="Factura" />
                  </RadioGroup>
                </FormControl>
              </Box>
              </>}
          </Box>
          }
        </Box>
        <Box
          padding='16px 24px'
          width='100%'
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
        >
          <Box>
            <Typography
              variant='body1'
              component='span'
              className={classes.regularText}
            >
              {'Subtotal:  '}
            </Typography>
            <Typography
              variant='body1'
              component='span'
              className={classes.regularText}
            >
              {`$ ${thousandSeparator(subTotal, '.')}`}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant='body1'
              component='span'
              className={classes.regularText}
            >
              {'Descuentos:  '}
            </Typography>
            <Typography
              variant='body1'
              component='span'
              className={classes.regularText}
            >
              {(coupon && coupon.value)
                ? [coupon.type === CUPON_PORCENTAJE
                  ? `${coupon.value * 100}% (max: $ ${thousandSeparator(coupon.maxDiscount, '.')})`
                  : `$ ${thousandSeparator(coupon.value, '.')} (max: $ ${thousandSeparator(coupon.maxDiscount || coupon.value, '.')})`]
                : 0}
            </Typography>
          </Box>
          <Box>
            <Box className={classes.priceBox}>
              <Typography
                variant='h4'
                component='span'
                className={classes.orangeTextTitle}
              >
                {'Total: '}
              </Typography>
              {(!editingCustomPrice) ? <Typography
                variant='h4'
                component='span'
                className={customTotal
                  ? classes.bluePriceTextTitleStrike : classes.bluePriceTextTitle}
              >
                {(coupon && coupon.actualDiscount)
                  ? `$ ${thousandSeparator(Math.max(subTotal - coupon.actualDiscount, 0), '.')}`
                  : `$ ${thousandSeparator(subTotal, '.')}`}
              </Typography> : ''}
              {(!editingCustomPrice && customTotal)
                ? <Typography
                  variant='h4'
                  component='span'
                  className={classes.orangeTextTitle}
                >
                  {`$ ${thousandSeparator(customTotal, '.')}`}
                </Typography> : ''}
              {changePrice ? <>
                <TextField
                  disabled={enableCustomPrice}
                  type='number'
                  className={(!enableCustomPrice)
                    ? classes.priceTextField : classes.hidden}
                  value={customTotal}
                  InputProps={{
                    startAdornment:
                      <Typography className={classes.bluePriceTextTitle}>
                        $
                      </Typography>,
                    endAdornment: editingCustomPrice ? <IconButton
                      onClick={() => {
                        setApplyCustomTotal(!applyCustomTotal);
                        setEnableCustomPrice(true);
                        setEditingCustomPrice(false);
                      }}>
                      <Check className={classes.orangeIcon} />
                    </IconButton> : '',
                    inputProps: {
                      min: -1,
                      className: classes.PriceTextTitle,
                    },
                    disableUnderline: enableCustomPrice,
                  }}
                  onChange={(e) => {
                    const { value } = e.target;
                    const number = Number(value);
                    if (value === '') {
                      setCustomTotal('');
                      return;
                    }
                    setCustomTotal(number);
                  }}
                />
                {enableCustomPrice ? <IconButton
                  onClick={() => {
                    setEditingCustomPrice(true);
                    setEnableCustomPrice(false);
                  }}>
                  <Edit2 className={classes.orangeIcon} />
                </IconButton> : ''}
                {!enableCustomPrice ? <IconButton
                  onClick={() => {
                    setEditingCustomPrice(false);
                    setEnableCustomPrice(true);
                    const resetValue = calculateSubTotal(productCart);
                    setCustomTotal(0);
                    setSubTotal(resetValue.subTotal);
                    setIndividualCustomPrice(resetValue.isCustomPrice);
                    Object.keys(productCart).forEach((productId) => {
                      dispatch(updateCustomPriceCart({ productId, customPrice: 0 }));
                    });
                  }}>
                  <RotateCcw />
                </IconButton>
                  : ''}
              </> : ''}
            </Box>
          </Box>
        </Box>
        <Box
          width='100%'
          padding='0px 24px'
        >
          <CustomButton
            type="submit"
            variant="contained"
            color='primary'
            onClick={handleGoToPay}
            buttoncolor='blue'
            disabled={editingCustomPrice}
          >
            Ir a pagar
          </CustomButton>
        </Box>
      </Box>
    </ClickAwayListener>
  );
}

export default TotalsToPay;

import React from 'react';
import { Radio, withStyles } from '@material-ui/core';

const CustomRadio = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.blue,
    '&$checked': {
      color: theme.palette.primary.orange,
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

export default CustomRadio;

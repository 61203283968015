import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  SvgIcon,
  Typography,
  Divider,
  Button,
  ClickAwayListener,
} from '@material-ui/core';
import {
  Minus as MinusIcon,
  Plus as PlusIcon,
  ShoppingCart as CartIcon,
  Trash2 as TrashIcon,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router';
import useStyles from './styles';
import thousandSeparator from '../../utils/thousandSeparator';
import CustomTextField from '../../components/Material/CustomTextfield';
import {
  addProductCart,
  removeProductCart,
  deleteProductCart,
  setLoadingSaleCart,
} from '../../actions/cartActions';
import { getProductsPricesStock } from '../../requests/api/products';
import handleApiResponse from '../../utils/handleApiResponse';
import PriceControl from './PriceControl';
import { textTruncation } from '../../utils/parser';
import SplashScreen from '../../components/Loading/SplashScreen';

// const values
const MAX_NUM_CHARS = 60;
const HEADER_AND_FOOTER_SIZE = 320;
const LIMIT = 50;

function DisplayItems() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const allRefs = useRef(false);
  const { enqueueSnackbar } = useSnackbar();

  const [allProducts, setAllProducts] = useState([]);
  const [productsToCart, setProductsToCart] = useState({});
  const [focusedTextfields, setFocusedTextfields] = useState({});
  const [itemsProductsInCart, setItemsProductsInCart] = useState(0);
  const [total, setTotal] = useState(0);
  const [moreProductsPage, setMoreProductsPage] = useState(0);
  const [noMoreProducts, setNoMoreProducts] = useState(false);

  const [seeMore, setSeeMore] = useState(-1);

  const { productCart, loading } = useSelector((state) => state.cart);
  const { selectedStore } = useSelector((state) => state.session);
  const { searchQuery } = useSelector((state) => state.product);
  const { customer } = useSelector((state) => state.customer);

  const handlingPriceProducts = (products) => {
    const auxProducts = [];
    let numOfProductsWithoutPrice = 0;
    for (let i = 0; i < products.length; i += 1) {
      const product = products[i];
      if (!product.priceListPrice) {
        numOfProductsWithoutPrice += 1;
      } else {
        const { customPrice } = productCart ? productCart[product.productId] || {} : {};
        if (customPrice) {
          auxProducts.push({
            ...product,
            customPrice,
          });
        } else {
          auxProducts.push(product);
        }
      }
    }

    if (numOfProductsWithoutPrice > 0) {
      handleApiResponse(
        enqueueSnackbar,
        { message: `Existe(n) ${numOfProductsWithoutPrice} producto(s) sin precio(s) asociados a esta tienda. Contactar proveedor` },
        true,
        'warning',
      );
    }

    return auxProducts;
  };

  const initialValuesForQuantityInCart = (products) => {
    let auxObj = {};
    let auxObjFocus = {};
    products.forEach((elem) => {
      auxObj = {
        ...auxObj,
        [elem.productId]: (
          productCart
          && productCart[elem.productId]
          && productCart[elem.productId].quantityInCart) || 0,
      };
      auxObjFocus = {
        ...auxObjFocus,
        [elem.productId]: false,
      };
    });
    setProductsToCart(auxObj);
    setFocusedTextfields(auxObjFocus);
  };

  const handleGetMoreItems = () => {
    setMoreProductsPage((prevState) => prevState + 1);
  };

  const handleAddOneItem = (product) => {
    const nextValue = (productCart
      && productCart[product.productId]
      && productCart[product.productId].quantityInCart + 1
    );
    if ((nextValue > product.quantity) || !product.quantity) {
      enqueueSnackbar('No puedes agregar más items de los que estan en stock o en tu carro  ', {
        variant: 'error',
      });
    } else {
      dispatch(addProductCart({ ...product, quantityToAdd: 1 }));
      setProductsToCart({
        ...productsToCart,
        [product.productId]: productsToCart[product.productId] + 1,
      });
    }
  };

  const handleRemoveOneItem = (product) => {
    if (productCart[product.productId].quantityInCart === 1) {
      dispatch(deleteProductCart({ productId: product.productId }));
      setProductsToCart({
        ...productsToCart,
        [product.productId]: 0,
      });
      return;
    }
    dispatch(removeProductCart({ ...product, quantityToRemove: 1 }));
    setProductsToCart({
      ...productsToCart,
      [product.productId]: productsToCart[product.productId] - 1,
    });
  };

  const handleGoCart = () => {
    if (productCart && Object.keys(productCart).length > 0) {
      history.push('/cart');
    } else {
      enqueueSnackbar('No tienes productos en el carrito', {
        variant: 'error',
      });
    }
  };

  const handleFocusTextField = (id) => {
    const newObj = Object.keys(focusedTextfields).reduce((accumulator, key) => ({
      ...accumulator,
      [key]: false,
    }), {});
    setFocusedTextfields({ ...newObj, [id]: true });
  };

  const handleClickAway = (id, product) => {
    setFocusedTextfields({ ...focusedTextfields, [id]: false });
    dispatch(deleteProductCart({ productId: id }));
    dispatch(addProductCart({ ...product, quantityToAdd: productsToCart[id] }));
    if (productsToCart[id] === 0) setItemsProductsInCart(0);
  };

  const handleTextFieldChange = (e, id, stock) => {
    const value = Number(e.target.value);
    const stockInCart = (productCart && productCart[id] && productCart[id].quantityInCart) || 0;

    if (!Number.isNaN(value) && value > 0 && value <= stock) {
      setProductsToCart((prevState) => ({ ...prevState, [id]: Number(value) }));
    } else if (!Number.isNaN(value) && value === 0) {
      setFocusedTextfields({ ...focusedTextfields, [id]: false });
      dispatch(deleteProductCart({ productId: id }));
      setProductsToCart({
        ...productsToCart,
        [id]: 0,
      });
    } else if (value > stockInCart) {
      enqueueSnackbar('No puedes agregar más items de los que estan en stock o en tu carro', {
        variant: 'error',
      });
    }
  };

  async function getAllProducts() {
    dispatch(setLoadingSaleCart(true));
    let response = {};
    try {
      response = await getProductsPricesStock({
        storeId: selectedStore.id,
        clientId: selectedStore.clientId,
        searchValue: searchQuery,
        limit: LIMIT,
        page: moreProductsPage,
      });
      const { products } = response;
      const availableProducts = handlingPriceProducts(products);
      setAllProducts(availableProducts);
      dispatch(setLoadingSaleCart(false));
      return availableProducts;
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
      return false;
    }
  }

  useEffect(() => {
    const initialRendering = async () => {
      const initialProducts = await getAllProducts();
      if (initialProducts) {
        initialValuesForQuantityInCart(initialProducts);
      }
    };

    initialRendering();
  }, []);

  useEffect(() => {
    const getQueryResults = async () => {
      await getAllProducts();
    };

    if (allRefs.current) {
      getQueryResults();
    } else {
      allRefs.current = true;
    }
  }, [searchQuery]);

  // Actualizacion cuando quiero ver mas productos
  useEffect(() => {
    const updateActualProducts = async () => {
      try {
        const response = await getProductsPricesStock({
          storeId: selectedStore.id,
          clientId: selectedStore.clientId,
          searchValue: searchQuery,
          limit: LIMIT,
          page: moreProductsPage,
        });
        const { products } = response;
        if (products.length === 0) {
          setNoMoreProducts(true);
        } else {
          const newAvailableProducts = handlingPriceProducts(products);
          initialValuesForQuantityInCart([...allProducts, ...newAvailableProducts]);
          setAllProducts((prevState) => [...prevState, ...newAvailableProducts]);
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    if (moreProductsPage) {
      updateActualProducts();
    }
  }, [moreProductsPage]);

  useEffect(() => {
    if (productCart) {
      const numOfItems = Object.keys(productCart).length;
      const calculateTotal = Object.keys(productCart).reduce((accum, key) => {
        const product = productCart[key];
        if (product.customPrice > 0) {
          return accum + (product.customPrice * product.quantityInCart);
        }
        if (product.discountPriceListPrice
          && (product.discountPriceListPrice < product.priceListPrice)) {
          return accum + product.discountPriceListPrice * product.quantityInCart;
        }
        return accum + product.priceListPrice * product.quantityInCart;
      }, 0);
      setItemsProductsInCart(numOfItems);
      setTotal(calculateTotal);
    } else {
      setItemsProductsInCart(0);
      setTotal(0);
    }
  }, [productCart]);

  return (
    <PerfectScrollbar
      style={{ flex: 1 }}
    >
      {loading
        ? <SplashScreen />
        : <Box
          height={(window.innerHeight - HEADER_AND_FOOTER_SIZE) > 0
            ? (window.innerHeight - HEADER_AND_FOOTER_SIZE)
            : 300}
        >
          <Table stickyHeader>
            <colgroup>
              <col style={{ width: '23%' }} />
              <col style={{ width: '62%' }} />
              <col style={{ width: '15%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={3}
                  className={classes.tableHeaderTableCell}
                >
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    minHeight='72px'
                    padding='0px 16px 0px 24px'
                  >
                    <Box
                      flex={1}
                    >
                      {customer
                        && <Box>
                          <Typography
                            variant='body1'
                            component='span'
                            className={classes.orangeText}
                          >
                            {'Cliente: '}
                          </Typography>
                          <Typography
                            variant='body1'
                            component='span'
                            className={classes.blueText}
                          >
                            {customer.name}
                          </Typography>
                        </Box>}
                      <Box>
                        <Typography
                          variant='body1'
                          component='span'
                          className={classes.orangeText}
                        >
                          {'Total: '}
                        </Typography>
                        <Typography
                          variant='body1'
                          component='span'
                          className={classes.blueText}
                        >
                          {`$ ${thousandSeparator(total, '.')}`}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider
                      orientation='vertical'
                      flexItem
                      className={classes.verticalDivider}
                    />
                    <Box
                      className={classes.svgIconContainer}
                    >
                      <Box className={classes.cartCircle}>
                        {itemsProductsInCart || 0}
                      </Box>
                      <SvgIcon
                        fontSize='large'
                        className={classes.blueSVG}
                        onClick={handleGoCart}
                      >
                        <CartIcon />
                      </SvgIcon>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!allProducts.length && allProducts.map((product, index) => (
                <TableRow
                  key={index}
                >
                  <TableCell className={classes.tableCell}>
                    <Box
                      display='flex'
                      flexDirection='column'
                    >
                      <Box>
                        <Typography
                          component='span'
                          variant='h4'
                          className={classes.boldTitle}
                        >
                          {seeMore !== index && `${textTruncation(product.name, MAX_NUM_CHARS)} `}
                          {seeMore === index && product.name}
                        </Typography>
                        {seeMore !== index
                          && product.name.length > MAX_NUM_CHARS
                          && <Typography
                            component='span'
                            onClick={() => setSeeMore(index)}
                            className={classes.seeMoreTypography}
                          >
                            ver más
                          </Typography>}
                      </Box>
                      <Box>
                        <Typography
                          variant='h4'
                          component='span'
                          className={classes.boldTitle}
                        >
                          {'SKU:  '}
                        </Typography>
                        <Typography
                          variant='body1'
                          component='span'
                          className={classes.regularText}
                        >
                          {product.sku}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          component='span'
                          variant='h4'
                          className={classes.boldTitle}
                        >
                          {'Stock:  '}
                        </Typography>
                        <Typography
                          variant='body1'
                          component='span'
                          className={classes.regularText}
                        >
                          {`${thousandSeparator(product.quantity, '.')}`}
                        </Typography>
                      </Box>
                      {product.discountPriceListPrice
                        && product.discountPriceListPrice < product.priceListPrice
                        && <Box>
                          <PriceControl
                            price={thousandSeparator(product.priceListPrice, '.')}
                            discountPrice={thousandSeparator(product.discountPriceListPrice, '.')}
                            setAllProducts={setAllProducts}
                            allProducts={allProducts}
                            product={product}
                          />
                        </Box>
                      }
                      {(!product.discountPriceListPrice
                        || product.discountPriceListPrice >= product.priceListPrice)
                        && <PriceControl
                          setAllProducts={setAllProducts}
                          allProducts={allProducts}
                          product={product}
                          price={thousandSeparator(product.priceListPrice, '.')}
                        />
                      }
                    </Box>
                  </TableCell>
                  {productCart !== null && productCart[product.productId]
                    && productCart[product.productId].quantityInCart ? (
                    <TableCell>
                      <Box
                        display='flex'
                        justifyContent='center'
                      >
                        <SvgIcon
                          className={classes.minusSymbol}
                          onClick={() => handleRemoveOneItem(product)}
                        >
                          {productCart[product.productId].quantityInCart === 1
                            ? <TrashIcon /> : <MinusIcon />}
                        </SvgIcon>
                        {!focusedTextfields[product.productId]
                          && <Box
                            className={classes.boldBlueNumber}
                            onClick={() => handleFocusTextField(product.productId)}
                          >
                            {(productCart && productCart[product.productId].quantityInCart) || 0}
                          </Box>
                        }
                        {focusedTextfields[product.productId]
                          && (
                            <ClickAwayListener onClickAway={
                              () => handleClickAway(product.productId, product)
                            }>
                              <Box className={classes.boldBlueNumber}>
                                <CustomTextField
                                  autoFocus
                                  onChange={(e) => handleTextFieldChange(
                                    e,
                                    product.productId,
                                    product.quantity,
                                    product,
                                  )}
                                  value={productsToCart && productsToCart[product.productId]}
                                  variant="standard"
                                />
                              </Box>
                            </ClickAwayListener>
                          )
                        }
                        <SvgIcon
                          className={classes.plusSymbol}
                          onClick={() => handleAddOneItem(product)}
                        >
                          <PlusIcon />
                        </SvgIcon>
                      </Box>
                    </TableCell>) : (
                    <TableCell
                      className={clsx(classes.rightAlignButton, classes.minWidth)}
                    >
                      <Button
                        key={product.productId}
                        type="submit"
                        size="large"
                        variant="contained"
                        color='primary'
                        className={clsx(classes.blueButton, classes.fontSizeButton)}
                        onClick={() => handleAddOneItem(product)}
                      >
                        +
                      </Button>
                    </TableCell>)
                  }
                </TableRow>
              ))}
              {!noMoreProducts && <Button
                onClick={handleGetMoreItems}
                className={classes.loadMoreProductsButton}
              >
                Cargar más productos...
              </Button>}
            </TableBody>
          </Table>
        </Box >
      }
    </PerfectScrollbar >
  );
}

export default DisplayItems;

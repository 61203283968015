import React from 'react';
import { Box } from '@material-ui/core';
import Page from '../../components/Layout/Page';

import Header from './Components/Header';
import CustomContainer from '../../components/Material/CustomContainer';
import ListView from './Components/ListView';

function CreditSaleView() {
  return (
    <Page
      title="Deudas de la tienda"
    >
      <CustomContainer
        maxWidth="md"
        nopadding="true"
      >
        <Box
          mb={2}
          display='flex'
          flexDirection='column'
          flex={1}
        >
          <Header />
          <ListView />
        </Box>
      </CustomContainer>
    </Page>
  );
}

export default CreditSaleView;

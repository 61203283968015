/* eslint-disable import/prefer-default-export */
import axios from '../../utils/axios';

const getCompaniesPerPage = async ({
  limit, page, searchValue, clientId, customerId,
}) => {
  const response = await axios.get(`/api/companies?clientId=${clientId}&customerId=${customerId}&limit=${limit}&page=${page}&searchValue=${searchValue}`);
  return response.data;
};

const getCompanyByIdentificacionNumber = async (searchValue) => {
  const response = await axios.get(`/api/company/${searchValue}`);
  return response.data;
};

const postNewCompany = async (data) => {
  const response = await axios.post('/api/company/create', data);
  return response.data;
};

export {
  getCompaniesPerPage,
  getCompanyByIdentificacionNumber,
  postNewCompany,
};

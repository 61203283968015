import React, { useState } from 'react';
import Page from '../../components/Layout/Page';

import Header from './Components/Header';
import Payment from './Components/Payment';
import CustomizedSplashScreenView from '../../components/Loading/CustomizedSplashScreen';
import CustomContainer from '../../components/Material/CustomContainer';

function PaymentView() {
  // const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [isLoadingBill, setIsLoadingBill] = useState(false);

  return (
    <Page
      title="Pago"
    >
      <CustomContainer
        maxWidth="md"
      >
        {!isLoadingBill /* && !isLoadingPayment */
          && <>
            <Header />
            <Payment
              // setIsLoadingPayment={setIsLoadingPayment}
              setIsLoadingBill={setIsLoadingBill}
            />
          </>
        }
        {/* {!isLoadingBill && isLoadingPayment
          && <CustomizedSplashScreenView
            tabTitle='Cargando...'
            firstTitle='Generando'
            secondTitle='Pago'
          />} */}
        {isLoadingBill /* && !isLoadingPayment */
          && <CustomizedSplashScreenView
            tabTitle='Cargando...'
            firstTitle='Generando'
            secondTitle='Boleta/Factura'
          />}

      </CustomContainer>
    </Page>
  );
}

export default PaymentView;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { ChevronRight as ChevronRightIcon, Search as SearchIcon } from 'react-feather';
import { format } from 'rut.js';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from '../styles';
import CustomTitleTypography from '../../../components/Material/CustomTitlesTypography';
import CustomTextField from '../../../components/Material/CustomTextfield';
import handleApiResponse from '../../../utils/handleApiResponse';
import { getDebtsPerPage } from '../../../requests/api/debts';
import thousandSeparator from '../../../utils/thousandSeparator';
import { resetSelectedCustomerDebt, setSelectedCustomerDebt } from '../../../actions/customerActions';
import { formatDate } from '../../../utils/date';

function ListView() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedStore } = useSelector((state) => state.session);
  const { clientId } = useSelector((state) => state.account.user);

  const [searchedCustomer, setSearchedCustomer] = useState('');
  const [relevantCustomers, setRelevantCustomers] = useState([]);

  const handleIndividualOrder = (id, name, lastname, rut, lastCredit, totalDebt, debtsIds) => {
    dispatch(setSelectedCustomerDebt({
      id,
      name,
      lastname,
      rut,
      lastCredit,
      totalDebt,
      debtsIds,
    }));
    history.push(`/store-options/credit-sale/order/${id}`);
  };

  const getAllCustomersDebt = async () => {
    try {
      const response = await getDebtsPerPage({
        limit: '', page: '', searchedCustomer, storeId: selectedStore.id, clientId,
      });

      const { debts } = response;
      const auxIndex = {};
      for (let i = 0; i < debts.length; i += 1) {
        const actualCustomerId = debts[i].order.customer.id;
        if (Object.keys(auxIndex).includes(actualCustomerId.toString())) {
          const lastDate = new Date(auxIndex[actualCustomerId].lastCredit);
          const prevDebtAmount = auxIndex[actualCustomerId].totalDebt;
          auxIndex[actualCustomerId] = {
            ...auxIndex[actualCustomerId],
            debtsIds: [...auxIndex[actualCustomerId].debtsIds, debts[i].id],
            totalDebt: prevDebtAmount + (debts[i].initialDebt - debts[i].amountPayed),
            lastCredit: lastDate > new Date(debts[i].createdAt)
              ? lastDate
              : new Date(debts[i].createdAt),
          };
        } else {
          auxIndex[actualCustomerId] = {
            customerId: actualCustomerId,
            name: debts[i].order.customer.name,
            lastname: debts[i].order.customer.lastname,
            rut: debts[i].order.customer.rut,
            totalDebt: (debts[i].initialDebt - debts[i].amountPayed),
            lastCredit: new Date(debts[i].createdAt),
            debtsIds: [debts[i].id],
          };
        }
      }
      setRelevantCustomers(Object.keys(auxIndex).map((item) => auxIndex[item]));
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleSearch = async () => {
    getAllCustomersDebt();
  };

  useEffect(() => {
    getAllCustomersDebt();
    dispatch(resetSelectedCustomerDebt());
  }, []);

  return (
    <Box
      display='flex'
      flexDirection='column'
      flex='1'
    >
      <Box
        className={classes.elevatedBox}
      >
        <CustomTextField
          fullWidth
          value={searchedCustomer}
          onChange={(e) => setSearchedCustomer(e.target.value)}
          color="secondary"
          placeholder='Buscar cliente'
          variant="outlined"
        />
        <IconButton
          className={classes.blueSearchIcon}
          onClick={handleSearch}
        >
          <SearchIcon />
        </IconButton>
      </Box>
      {relevantCustomers && relevantCustomers.length > 0 && relevantCustomers.map((credit) => (
          <Box
            key={credit.customerId}
            onClick={() => handleIndividualOrder(
              credit.customerId,
              credit.name,
              credit.lastname,
              credit.rut,
              credit.lastCredit,
              credit.totalDebt,
              credit.debtsIds,
            )}
          >
            <Box
              mt={3}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              padding={'0px 24px'}
            >

              <Box
                display='flex'
                flexDirection='column'
              >
                <Box>
                  <Typography
                    variant="inherit"
                    color="primary"
                    className={classes.cardTitleTypography}
                  >
                    {`Deuda ${credit.name} ${credit.lastname}`}
                  </Typography>
                </Box>
                <Box
                  className={classes.cardDetail}
                >
                  <CustomTitleTypography
                    firstHighlight='Rut:'
                    secondHighlight={format(credit.rut)}
                    fontSize='16px'
                    blueFirst={true}
                    textAlign='left'
                  />
                  <Typography
                    variant="h3"
                    color="textSecondary"
                    className={classes.cardDetailTypography}
                  >
                    {`Último fiado: ${formatDate(credit.lastCredit)}`}
                  </Typography>
                  <CustomTitleTypography
                    firstHighlight='Deuda total:'
                    secondHighlight={`$ ${thousandSeparator(credit.totalDebt, '.')}`}
                    fontSize='16px'
                    blueFirst={true}
                    textAlign='left'
                  />
                </Box>
              </Box>
              <Box>
                <ChevronRightIcon
                  className={classes.chevronRightSymbol}
                />
              </Box>

            </Box>
            <Divider
              className={classes.marginDivider}
            />
          </Box>
      ))}
      {relevantCustomers && relevantCustomers.length === 0
        && <Box
          mt={2}
          display='flex'
          flex={1}
          justifyContent='center'
        >
          <Typography
            className={classes.cardDetailTypography}
          >
            La tienda no tiene fiados asociados
          </Typography>

        </Box>}
    </Box>
  );
}

export default ListView;

export const RESET_PRODUCT_STOCK = '@product/reset-product-stock';
export const ADD_PRODUCT_STOCK = '@product/add-product-stock';
export const SEARCH_QUERY_PRODUCTS = '@product/search-query-products';
export const RESET_ALL_PRODUCT_STOCK = '@product/reset-all-product-stock';
export const SET_ALL_PRODUCT_STOCK = '@product/set-all-product-stock';

export function resetProductStock() {
  return {
    type: RESET_PRODUCT_STOCK,
  };
}

export function addProductStock(payload) {
  return {
    type: ADD_PRODUCT_STOCK,
    payload,
  };
}

export function searchQueryProducts(payload) {
  return {
    type: SEARCH_QUERY_PRODUCTS,
    payload,
  };
}

export function resetAllProductStock() {
  return {
    type: RESET_ALL_PRODUCT_STOCK,
  };
}

export function setAllProductStock(payload) {
  return {
    type: SET_ALL_PRODUCT_STOCK,
    payload,
  };
}

import React from 'react';
import Page from '../../components/Layout/Page';

import StoreOptions from './StoreOptions';
import CustomContainer from '../../components/Material/CustomContainer';

function StoreOptionsView() {
  return (
    <Page
      title="Opciones Tienda"
    >
      <CustomContainer maxWidth="md">
        <StoreOptions />
      </CustomContainer>
    </Page>
  );
}

export default StoreOptionsView;

import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import customerReducer from './customerReducer';
import sessionReducer from './sessionReducer';
import productReducer from './productReducer';
import cartReducer from './cartReducer';
import returnsReducer from './returnsReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  customer: customerReducer,
  session: sessionReducer,
  product: productReducer,
  cart: cartReducer,
  returns: returnsReducer,
  form: formReducer,
});

export default rootReducer;

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from 'react-feather';

import useStyles from '../styles';

function Header() {
  const classes = useStyles();
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      mb={2}
    >
      <Box
        display='flex'
        alignItems='center'
      >
        <SvgIcon
          fontSize='large'
          className={classes.chevronLeftSymbol}
          onClick={handleBack}
        >
          <ChevronLeftIcon/>
        </SvgIcon>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          flex={1}
        >
          <Box>
            <Typography
              variant='h1'
              component='span'
              className={classes.orangeTextTitle}
            >
              Devoluciones
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;

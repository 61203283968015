import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';

import Page from '../../components/Layout/Page';
import CheckStore from './CheckStore';
import { getUserStoresById } from '../../requests/api/users';
import {
  resetStore,
  setStore,
} from '../../actions/sessionActions';
import handleApiResponse from '../../utils/handleApiResponse';
import CustomContainer from '../../components/Material/CustomContainer';

function CheckStoreView() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { id: userId } = useSelector((state) => state.account.user);
  const { rdcPayment } = useSelector((state) => state.session.moduleInformation);

  const [stores, setStores] = useState([]);

  useEffect(() => {
    const checkUserStores = async () => {
      try {
        const response = await getUserStoresById(userId);
        const { stores: allStores } = response;
        if (allStores.length === 0) {
          handleApiResponse(enqueueSnackbar, { message: 'No tienes tiendas asociadas a este usuario. Contactar proveedor' }, true, 'warning');
        } else if (allStores.length === 1) {
          dispatch(setStore(allStores[0]));
          if (rdcPayment) {
            history.push('/app/maquinas');
          } else {
            history.push('/store-options');
          }
        } else {
          setStores(allStores);
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    dispatch(resetStore());
    checkUserStores();
  }, []);

  return (
    <Page
      title="Seleccionar Tienda"
    >
      <CustomContainer maxWidth="md">
        <CheckStore
          stores={stores}
        />
      </CustomContainer>
    </Page>
  );
}

export default CheckStoreView;

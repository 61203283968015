/* eslint-disable import/prefer-default-export */
import axios from '../../utils/axios';

const postNewOrder = async (orderObj) => {
  const response = await axios.post('/api/order/create', orderObj);
  return response.data;
};

const getOrderByBillingNumber = async ({ billNumber }) => {
  const response = await axios.get(`/api/order/bill-number/${billNumber}`);
  return response.data;
};

const cancelOrder = async (orderObj) => {
  const response = await axios.put('/api/order/cancel-order', orderObj);
  return response.data;
};

const putCompleteOrder = async (updateObj) => {
  const response = await axios.put('/api/order/complete-order', updateObj);
  return response.data;
};

const getOrdersAllBills = async ({
  storeId, clientId, dateFilter, billNumberFilter,
}) => {
  const response = await axios.get(`/api/orders/all-available-bills?clientId=${clientId}&storeId=${storeId}&dateFilter=${dateFilter}&billNumberFilter=${billNumberFilter}`);
  return response.data;
};

export {
  postNewOrder,
  getOrderByBillingNumber,
  cancelOrder,
  putCompleteOrder,
  getOrdersAllBills,
};

import React from 'react';
import Page from '../../components/Layout/Page';

import useStyles from './styles';
import Header from './Components/Header';
import BillForm from './Components/BillForm';
import CustomContainer from '../../components/Material/CustomContainer';

function BillingFormView() {
  const classes = useStyles();

  return (
    <Page
      title="Factura"
    >
      <CustomContainer
        maxWidth="md"
        className={classes.container}
      >
        <Header />
        <BillForm />
      </CustomContainer>
    </Page>
  );
}

export default BillingFormView;

import React from 'react';
import ErrorView from '../../components/Error';

function ErrorSaleFinalView() {
  return (
    <ErrorView
      tabTitle={'Error transaccón'}
      title={'Error'}
      subTitle={'La transacción no se puedo llevar a cabo'}
    />
  );
}

export default ErrorSaleFinalView;

const SALE = 'venta'; // venta products
const RETURN = 'devolucion'; // devolucion de productos
const ERROR = 'error'; // error de ingreso o egreso
const STOCK = 'ingresoStock'; // ingreso de stock
const LOSSES = 'merma'; // productos que se rompen/pierden

module.exports = {
  SALE,
  RETURN,
  ERROR,
  STOCK,
  LOSSES,
};

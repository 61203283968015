export const RESET_RDC_MACHINE = '@session/reset-RDC-machine';
export const SET_RDC_MACHINE = '@session/set-RDC-machine';
export const RESET_STORE = '@session/reset-store';
export const SET_STORE = '@session/set-store';
export const RESET_ORDER_ID = '@session/reset-order_id';
export const SET_ORDER_ID = '@session/set-order_id';
export const RESET_TIMES_ERROR = '@session/reset-times-error';
export const SET_TIMES_ERROR = '@session/set-times-error';
export const RESET_MODULE = '@session/reset-module';
export const SET_MODULE = '@session/set-module';

export function resetRDCMachine() {
  return {
    type: RESET_RDC_MACHINE,
  };
}

export function setRDCMachine(payload) {
  return {
    type: SET_RDC_MACHINE,
    payload,
  };
}

export function resetStore() {
  return {
    type: RESET_STORE,
  };
}

export function setStore(payload) {
  return {
    type: SET_STORE,
    payload,
  };
}

export function resetOrderId() {
  return {
    type: RESET_ORDER_ID,
  };
}

export function setOrderId(payload) {
  return {
    type: SET_ORDER_ID,
    payload,
  };
}

export function resetTimesError() {
  return {
    type: RESET_TIMES_ERROR,
  };
}

export function setTimesError(payload) {
  return {
    type: SET_TIMES_ERROR,
    payload,
  };
}

export function resetModule() {
  return {
    type: RESET_MODULE,
  };
}

export function setModule(payload) {
  return {
    type: SET_MODULE,
    payload,
  };
}

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  displayItemsWrapper: {
    maxHeight: '250px',
    '@media screen and ( min-height: 720px )': {
      maxHeight: '325px',
    },
    '@media screen and ( min-height: 825px )': {
      maxHeight: '425px',
    },
    '@media screen and ( min-height: 900px )': {
      maxHeight: '500px',
    },
  },
  blueTextTitle: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
  },
  blueText: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
    fontSize: theme.fontSizes.bodyText,
  },
  bluePriceTextTitle: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
    fontSize: '22px',
  },
  blueTextUnderlinedPayment: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
    textDecoration: 'underline',
    fontSize: theme.fontSizes.bodyText,
  },
  blueTextUnderlined: {
    color: theme.palette.primary.blue,
    textDecoration: 'line-through',
    fontSize: theme.fontSizes.bodyText,
  },
  orangeTextTitle: {
    color: theme.palette.primary.orange,
    fontWeight: 700,
    fontSize: '22px',
  },
  bluePriceTextTitleStrike: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
    fontSize: '22px',
    textDecoration: 'line-through',
    marginRight: '10px',
  },
  orangeText: {
    color: theme.palette.primary.orange,
    fontWeight: 700,
    fontSize: theme.fontSizes.bodyText,
  },
  boldTitle: {
    fontSize: theme.fontSizes.bodyText,
    fontWeight: 700,
    color: theme.palette.primary.blue,
  },
  regularText: {
    fontSize: theme.fontSizes.bodyText,
    color: theme.palette.primary.blue,
  },
  tableHeader: {
    height: '40px',
    fontSize: theme.fontSizes.tableHeader,
  },
  tableHeaderMaxWidth: {
    maxWidth: 'calc(100vh/4)',
  },
  tableHeaderColor: {
    backgroundColor: '#f1f1f1',
  },
  tableHeaderTableCellLeft: {
    padding: '0px 8px 0px 16px',
    fontSize: theme.fontSizes.tableHeader,
    fontWeight: 700,
    color: theme.palette.primary.blue,
  },
  tableHeaderTableCellRight: {
    padding: '0px 16px 0px 8px',
    textAlign: 'center',
    fontSize: theme.fontSizes.tableHeader,
  },
  tableHeaderTableCell: {
    padding: '0px 8px',
    textAlign: 'center',
    fontSize: theme.fontSizes.tableHeader,
  },
  tableCellBodyLeft: {
    padding: '24px 8px 24px 16px',
    fontSize: theme.fontSizes.tableCell,
  },
  tableCellBody: {
    padding: '24px 8px 24px 8px',
    textAlign: 'center',
    fontSize: theme.fontSizes.tableCell,
  },
  tableCellBodyRight: {
    padding: '24px 16px 24px 8px',
    textAlign: 'center',
    fontSize: theme.fontSizes.tableCell,
  },
  boldBlueNumber: {
    fontSize: '25px',
    color: theme.palette.primary.blue,
    fontWeight: 700,
  },
  minusSymbol: {
    margin: '5px 10px 0px 0px',
    color: theme.palette.primary.orange,
  },
  plusSymbol: {
    margin: '5px 0px 0px 10px',
    color: theme.palette.primary.orange,
  },
  chevronLeftSymbol: {
    color: theme.palette.primary.blue,
  },
  blueButton: {
    backgroundColor: theme.palette.primary.blue,
    fontWeight: 'bold',
    color: 'white',
    width: '100%',
    maxWidth: '400px',
  },
  showOptionsBox: {
    backgroundColor: '#f1f1f1',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  showOptionsBoxNoOptions: {
    backgroundColor: '#f1f1f1',
    width: '100%',
    height: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  marginDivider: {
    border: '0.5px solid',
    borderColor: theme.palette.primary.blue,
    margin: '15px 0px',
  },
  // boxDivider: {
  //   border: '10px solid',
  //   borderColor: theme.palette.primary.blue,
  //   margin: '15px 0px',
  // },
  couponButton: {
    backgroundColor: '#d9d9d9',
    fontSize: theme.fontSizes.bodyText,
    fontWeight: 700,
    textAlign: 'center',
    border: '3px solid',
    borderColor: theme.palette.primary.blue,
    color: theme.palette.primary.blue,
    marginLeft: '6px',
  },
  minWidthButton: {
    minWidth: '110px',
    height: '38px',
  },
  widthSelectButton: {
    width: '34px',
    height: '40px',
  },
  marginLeft: {
    marginLeft: '15px',
  },
  radioButtonLabel: {
    color: theme.palette.primary.blue,
    fontSize: theme.fontSizes.bodyText,
  },
  blueTextPriceCustom: {
    fontSize: '16px',
    color: theme.palette.primary.blue,
    fontWeight: 500,
  },
  priceBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  priceTextField: {
    width: '180px',
    padding: '0px 8px',
    '& input': {
      textAlign: 'left',
      fontSize: '24px',
      fontWeight: 700,
      color: theme.palette.primary.blue,
    },
  },
  priceBlueTextField: {
    width: '180px',
    padding: '0px 8px',
    '& input': {
      textAlign: 'left',
      fontSize: '24px',
      fontWeight: 700,
      color: theme.palette.primary.blue,
    },
  },
  dollarSign: {
    color: theme.palette.primary.orange,
    fontWeight: 700,
    fontSize: '28px',
  },
  orangeIcon: {
    color: theme.palette.primary.orange,
  },
  customPriceButton: {
    marginTop: '10px',
  },
  hidden: {
    display: 'none',
  },
}));

export default useStyles;

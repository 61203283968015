const paymentMethods = [{
  label: 'Efectivo',
  type: 'EFECTIVO',
},
{
  label: 'Transferencia',
  type: 'TRANSFERENCIA',
},
{
  label: 'Tarjeta de crédito',
  type: 'TARJETA_CREDITO',
},
{
  label: 'Tarjeta de débito',
  type: 'TARJETA_DEBITO',
},
{
  label: 'Fiado',
  type: 'FIADO',
},
];

export const PAYMENT_IN_DEBT = 'FIADO';

export default paymentMethods;

/* eslint-disable react/display-name */
import React, {
  forwardRef,
  useEffect, useCallback,
} from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import track from '../../utils/analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    // backgroundColor: theme.palette.background.dark,
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 24,
    paddingTop: 24,
    '& a': {
      textAlign: 'center',
      display: 'block',
      color: theme.palette.primary.white,
      textDecoration: 'none',
      fontSize: '16px',
    },
  },
}));

const Page = forwardRef(({
  title,
  children,
  ...rest
}, ref) => {
  const location = useLocation();
  const classes = useStyles();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <div
      ref={ref}
      className={classes.root}
      {...rest}
    >
      <Helmet>
        <title>{title}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Page;

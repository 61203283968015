import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import {
  Box, Table, TableBody, TableCell, TableHead, TableRow, SvgIcon, Typography, ClickAwayListener,
} from '@material-ui/core';
import {
  Plus as PlusIcon,
  Minus as MinusIcon,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';

import useStyles from '../styles';
import { addProductStock } from '../../../actions/productActions';
import thousandSeparator from '../../../utils/thousandSeparator';
import CustomTextField from '../../../components/Material/CustomTextfield';

function DisplayItems() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [focusedTextfields, setFocusedTextfields] = useState({});

  const {
    allProducts,
    addStock,
  } = useSelector((state) => state.product);

  const handleFocusTextField = (id) => {
    const newObj = Object.keys(focusedTextfields).reduce((accumulator, key) => ({
      ...accumulator,
      [key]: false,
    }), {});
    setFocusedTextfields({ ...newObj, [id]: true });
  };

  const handleClickAway = (id) => {
    setFocusedTextfields({ ...focusedTextfields, [id]: false });
  };

  const handleTextFieldChange = (e, id) => {
    const value = Number(e.target.value);

    if (!Number.isNaN(value) && value >= 0) {
      dispatch(addProductStock({
        ...addStock,
        [id]: {
          ...addStock[id],
          toAdd: value,
        },
      }));
    }
  };

  const handleAddOneItem = (id) => {
    dispatch(addProductStock({
      ...addStock,
      [id]: {
        ...addStock[id],
        toAdd: addStock[id].toAdd + 1,
      },
    }));
  };

  const handleRemoveOneItem = (id) => {
    if (addStock[id].toAdd > 0) {
      dispatch(addProductStock({
        ...addStock,
        [id]: {
          ...addStock[id],
          toAdd: addStock[id].toAdd - 1,
        },
      }));
    }
  };

  useEffect(() => {
    // Manage actual products stock
    if (allProducts && allProducts.length > 0) {
      let auxProducts = {};
      allProducts.forEach((prd) => {
        auxProducts = {
          ...auxProducts,
          [prd.id]: {
            toAdd: prd.quantityToAdd,
            actual: 0,
            cost: prd.unitCost,
            // actual: prd.quantity,
          },
        };
      });
      dispatch(addProductStock(auxProducts));
    }
  }, [allProducts]);

  return (
    <PerfectScrollbar
      style={{ flex: 1 }}>
      <Box
        minHeight='200px'
      >
        <Table stickyHeader>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={clsx(
                classes.tableHeaderTableCellLeft,
                classes.tableHeaderColor,
              )}>
                Item
              </TableCell>
              {/* POssibly adding it afterwards */}
              {/* <TableCell className={clsx(classes.tableCellCenter, classes.regularText)}>
                Ingresado
              </TableCell> */}
              <TableCell className={clsx(
                classes.tableHeaderTableCellRight,
                classes.tableHeaderColor,
              )}>
                Por ingresar
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allProducts && allProducts.map((product) => (
              <TableRow key={product.id}>
                <TableCell>
                  <Box>
                    <Typography
                      variant='h4'
                      className={classes.boldTitle}
                    >
                      {product.name}
                    </Typography>
                    <Box>
                      <Typography
                        variant='h4'
                        component='span'
                        className={classes.boldTitle}
                      >
                        {'SKU:  '}
                      </Typography>
                      <Typography
                        variant='body1'
                        component='span'
                        className={classes.regularText}
                      >
                        {product.sku}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        component='span'
                        variant='h4'
                        className={classes.boldTitle}
                      >
                        {'EAN:  '}
                      </Typography>
                      <Typography
                        variant='body1'
                        component='span'
                        className={classes.regularText}
                      >
                        {product.ean}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant='h4'
                        component='span'
                        className={classes.boldTitle}
                      >
                        {'Costo:  '}
                      </Typography>
                      <Typography
                        variant='body1'
                        component='span'
                        className={classes.regularText}
                      >
                        {`$ ${thousandSeparator(product.unitCost, '.')}`}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                {/* Possibly adding it afterwards */}
                {/* <TableCell className={classes.tableCellCenter}>
                  <Box className={classes.boldBlueNumber}>
                    {product.quantity}
                  </Box>
                </TableCell> */}
                <TableCell>
                  <Box
                    display='flex'
                    justifyContent='center'
                  >
                    <SvgIcon
                      className={classes.minusSymbol}
                      onClick={() => handleRemoveOneItem(product.id)}
                    >
                      <MinusIcon />
                    </SvgIcon>
                    {!focusedTextfields[product.id]
                      && <Box
                        className={classes.boldBlueNumber}
                        onClick={() => handleFocusTextField(product.id)}
                      >
                        {(addStock && addStock[product.id] && addStock[product.id].toAdd) || 0}
                      </Box>
                    }
                    {focusedTextfields[product.id]
                      && (
                        <ClickAwayListener onClickAway={() => handleClickAway(product.id)}>
                          <Box
                            maxHeight='40px'
                            maxWidth='40px'
                          >
                            <CustomTextField
                              autoFocus
                              onChange={(e) => handleTextFieldChange(
                                e,
                                product.id,
                              )}
                              value={(addStock
                                && addStock[product.id]
                                && addStock[product.id].toAdd) || 0}
                              variant="outlined"
                            />
                          </Box>
                        </ClickAwayListener>
                      )
                    }
                    <SvgIcon
                      className={classes.plusSymbol}
                      onClick={() => handleAddOneItem(product.id)}
                    >
                      <PlusIcon />
                    </SvgIcon>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
  );
}

export default DisplayItems;

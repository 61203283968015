import React, { useState } from 'react';
import Page from '../../components/Layout/Page';

import Header from './Components/Header';
import Payment from './Components/Payment';
import CustomizedSplashScreenView from '../../components/Loading/CustomizedSplashScreen';
import CustomContainer from '../../components/Material/CustomContainer';

function CreditSalePaymentView() {
  const [isLoadingBill, setIsLoadingBill] = useState(false);

  return (
    <Page
      title="Pago"
    >
      <CustomContainer
        maxWidth="md"
      >
        {!isLoadingBill
          && <>
            <Header />
            <Payment
              setIsLoadingBill={setIsLoadingBill}
            />
          </>
        }
        {isLoadingBill
          && <CustomizedSplashScreenView
            tabTitle='Cargando...'
            firstTitle='Generando'
            secondTitle='Boleta/Factura'
          />}

      </CustomContainer>
    </Page>
  );
}

export default CreditSalePaymentView;

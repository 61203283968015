/* eslint-disable react/display-name */
import React, { Fragment, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthGuard from './components/Auth/AuthGuard';
import LoadingScreen from './components/Loading/LoadingScreen';

import Login from './views/Login';
import Verification from './views/Verification';
import PasswordRecovery from './views/PasswordRecovery';
import MachinesView from './views/Machines';
import ClientTypeView from './views/ClientType';
import StoreOptionsView from './views/StoreOptions';
import ReturnsView from './views/Returns';
import StockView from './views/Stock';
import AuthComponent from './components/Auth/AuthComponent';
import CheckStoreView from './views/CheckStore';
import CreateClientView from './views/CreateClient';
import SaleView from './views/Sale';
import CreditSaleView from './views/CreditSale';
import CreditSaleDetailView from './views/CreditSaleDetail';
import CartView from './views/Cart';
import BillingFormView from './views/BillingForm';
import PaymentView from './views/Payment';
import ReturnsDetailView from './views/ReturnsDetail';
import SuccessReturnsFinalView from './views/ReturnsFinal/success';
import SuccessSaleFinalView from './views/SaleFinal/success';
import ErrorSaleFinalView from './views/SaleFinal/error';
import ErrorReturnsFinalView from './views/ReturnsFinal/error';
import CreditSaleBillDetailView from './views/CreditSaleBillDetail';
import CreditSalePaymentView from './views/CreditSalePayment';

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />,
  },
  {
    id: 2,
    exact: true,
    path: '/login',
    component: () => <Login />,
  },
  // TODO: Si el usuario no puede realizar su propio registro
  // (desde fuera del Dashboard) comentar esta ruta.
  {
    id: 3,
    exact: true,
    path: '/user-verification/:token',
    component: () => <Verification />,
  },
  {
    id: 4,
    exact: true,
    path: '/reset-password/:token',
    component: () => <PasswordRecovery />,
  },
  {
    id: 5,
    exact: true,
    path: '/reset-password?token=:token',
    component: () => <Redirect to="/reset-password/:token" />,
  },
  {
    path: '/app',
    guard: AuthGuard,
    // layout: DashboardLayout, // usar aca un layout nuevo para el POS
    routes: [
      {
        id: 11,
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/check-store" />,
      },
      {
        id: 12,
        exact: true,
        path: '/app/maquinas',
        guard: () => AuthComponent({
          children: <MachinesView />,
          selector: (state) => state.session.selectedStore,
          route: '/check-store',
        }),
        component: () => <MachinesView />,
      },
      {
        id: 100,
        component: () => <Redirect to="/app/check-store" />, // Cambiar después por home
      },
    ],
  },
  {
    path: '/store-options',
    guard: AuthGuard,
    routes: [
      {
        id: 1001,
        exact: true,
        path: '/store-options',
        guard: () => AuthComponent({
          children: <StoreOptionsView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <StoreOptionsView />,
      },
      {
        id: 1002,
        exact: true,
        path: '/store-options/returns',
        guard: () => AuthComponent({
          children: <ReturnsView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <ReturnsView />,
      },
      {
        id: 999,
        exact: true,
        path: '/store-options/returns/detail',
        guard: () => AuthComponent({
          children: <ReturnsDetailView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <ReturnsDetailView />,
      },
      {
        id: 998,
        exact: true,
        path: '/store-options/returns/success',
        guard: () => AuthComponent({
          children: <SuccessReturnsFinalView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <SuccessReturnsFinalView />,
      },
      {
        id: 997,
        exact: true,
        path: '/store-options/returns/error',
        guard: () => AuthComponent({
          children: <ErrorReturnsFinalView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <ErrorReturnsFinalView />,
      },
      {
        id: 1003,
        exact: true,
        path: '/store-options/client-type',
        guard: () => AuthComponent({
          children: <ClientTypeView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <ClientTypeView />,
      },
      {
        id: 1004,
        exact: true,
        path: '/store-options/enter-stock',
        guard: () => AuthComponent({
          children: <StockView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <StockView />,
      },
      {
        id: 1006,
        exact: true,
        path: '/store-options/create-client',
        guard: () => AuthComponent({
          children: <CreateClientView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <CreateClientView />,
      },
      {
        id: 996,
        exact: true,
        path: '/store-options/credit-sale',
        guard: () => AuthComponent({
          children: <CreditSaleView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <CreditSaleView />,
      },
      {
        id: 995,
        exact: true,
        path: '/store-options/credit-sale/order/:orderId',
        guard: () => AuthComponent({
          children: <CreditSaleDetailView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <CreditSaleDetailView />,
      },
      {
        id: 994,
        exact: true,
        path: '/store-options/credit-sale/bill/:debtId',
        guard: () => AuthComponent({
          children: <CreditSaleBillDetailView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <CreditSaleBillDetailView />,
      },
      {
        id: 993,
        exact: true,
        path: '/store-options/credit-sale/payments',
        guard: () => AuthComponent({
          children: <CreditSalePaymentView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <CreditSalePaymentView />,
      },
    ],
  },
  {
    path: '/sale',
    guard: AuthGuard,
    routes: [
      {
        id: 1007,
        exact: true,
        path: '/sale',
        guard: () => AuthComponent({
          children: <SaleView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <SaleView />,
      },
    ],
  },
  {
    path: '/check-store',
    guard: AuthGuard,
    routes: [
      {
        id: 1008,
        exact: true,
        path: '/check-store',
        component: () => <CheckStoreView />,
      },
    ],
  },
  {
    path: '/cart',
    guard: AuthGuard,
    routes: [
      {
        id: 1010,
        exact: true,
        path: '/cart',
        guard: () => AuthComponent({
          children: <CartView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <CartView />,
      },
      {
        id: 1011,
        exact: true,
        path: '/cart/bill',
        guard: () => AuthComponent({
          children: <BillingFormView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <BillingFormView />,
      },
      {
        id: 1012,
        exact: true,
        path: '/cart/payments',
        guard: () => AuthComponent({
          children: <PaymentView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <PaymentView />,
      },
      {
        id: 1013,
        exact: true,
        path: '/cart/success',
        guard: () => AuthComponent({
          children: <SuccessSaleFinalView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <SuccessSaleFinalView />,
      },
      {
        id: 1013,
        exact: true,
        path: '/cart/error',
        guard: () => AuthComponent({
          children: <ErrorSaleFinalView />,
          selector: (state) => state.session.selectedMachine,
          redirectValidator: (state) => state.session.moduleInformation.rdcPayment,
          route: '/app/maquinas',
        }),
        component: () => <ErrorSaleFinalView />,
      },
    ],
  },
  {
    id: 1000,
    component: () => <Redirect to="/login" />, // Cambiar después por home
  },
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;

import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import clsx from 'clsx';
import useStyles from '../styles';
import thousandSeparator from '../../../utils/thousandSeparator';

function CreditSaleBillDetailTable({ allProducts }) {
  const classes = useStyles();

  return (
    <PerfectScrollbar >
      <Box
        className={classes.displayItemsWrapper}
      >
        <Table stickyHeader>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={clsx(
                classes.tableHeaderTableCellLeft,
                classes.tableHeaderMaxWidth,
                classes.tableHeaderColor,
              )}>
                Item
              </TableCell>
              <TableCell className={
                clsx(
                  classes.tableHeaderMaxWidth,
                  classes.regularText,
                  classes.tableHeaderTableCell,
                  classes.tableHeaderColor,
                )}>
                Cantidad
              </TableCell>
              <TableCell className={
                clsx(
                  classes.tableHeaderMaxWidth,
                  classes.regularText,
                  classes.tableHeaderTableCell,
                  classes.tableHeaderColor,
                )}>
                Precio
              </TableCell>
              <TableCell className={
                clsx(
                  classes.tableHeaderMaxWidth,
                  classes.regularText,
                  classes.tableHeaderTableCell,
                  classes.tableHeaderColor,
                )}>
                Subtotal
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allProducts && allProducts.map((product) => (
            <TableRow
              key={product.productId}
            >
              <TableCell className={classes.tableCellBodyLeft}>
                <Typography
                  variant='h4'
                  className={classes.itemStyle}
                >
                  {product.product.name}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCellBodyRight}>
                <Box
                  display='flex'
                  justifyContent='center'
                  flexDirection='column'
                  className={classes.quantityStyle}
                >
                  {product.quantity}
                </Box>
              </TableCell>
              <TableCell className={classes.tableCellBodyRight}>
                <Box
                  display='flex'
                  justifyContent='center'
                  flexDirection='column'
                  className={classes.priceStyle}
                >
                  {`$ ${thousandSeparator(product.unitPrice, '.')}`}
                </Box>
              </TableCell>
              <TableCell className={classes.tableCellBodyRight}>
                <Box
                  // display='flex'
                  // justifyContent='center'
                  // flexDirection='column'
                  className={classes.priceStyle}
                >
                  {`$ ${thousandSeparator(product.totalPrice, '.')}`}
                </Box>
              </TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
  );
}

export default CreditSaleBillDetailTable;

/* eslint-disable import/prefer-default-export */
import axios from '../../utils/axios';

const getMachinesPerPage = async (storeId, clientId) => {
  const response = await axios.get(`/api/machines?storeId=${storeId}&clientId=${clientId || 0}`);
  return response.data;
};

export {
  getMachinesPerPage,
};

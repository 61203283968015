/* eslint-disable import/prefer-default-export */
import axios from '../../utils/axios';

const postReturn = async (returnProducts) => {
  const response = await axios.post('/api/return/create', returnProducts);
  return response.data;
};

export {
  postReturn,
};

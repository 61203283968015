/* eslint-disable import/prefer-default-export */
import axios from '../../utils/axios';

const getCouponAuthentication = async (couponInfo) => {
  const allParams = new URLSearchParams(couponInfo);
  const response = await axios.get(`/api/coupon/authentication?${allParams}`);
  return response.data;
};

export {
  getCouponAuthentication,
};

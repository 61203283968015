import { Container, withStyles } from '@material-ui/core';

const CustomContainer = withStyles(() => ({
  root: {
    padding: (props) => (props.nopadding ? '0px' : '0px 24px'),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
}))(Container);

export default CustomContainer;

import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetReturnsFilters } from '../../actions/returnsActions';
import Page from '../../components/Layout/Page';

import CustomContainer from '../../components/Material/CustomContainer';
import DisplayItems from './Components/DisplayItems';
import Header from './Components/Header';
import ReturnsFilter from './Components/ReturnsFilter';

function ReturnsView() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetReturnsFilters());
  }, []);
  return (
    <Page
      title="Devoluciones"
    >
      <CustomContainer
        maxWidth="md"
      >
        <Header />
        {/* <Returns /> */}
        <Box
          display='flex'
          flexDirection='column'
          flex={1}
        >
          <ReturnsFilter />
          <Box
            flex={1}
            mt={2}
          >
          <DisplayItems />
          </Box>

        </Box>

      </CustomContainer>
    </Page>
  );
}

export default ReturnsView;

import { TextField, styled } from '@material-ui/core';

const CustomTextFieldV2 = styled(TextField)(({ theme }) => ({
  // Input component
  '& .MuiInputBase-input': {
    padding: '12px 16px',
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.primary.blue,
    backgroundColor: 'white',
  },
  // FormControl
  '& .MuiTextField-root': {
    margin: '0px 5px 0px 0px',
  },
  // Placeholder
  '& .MuiFormLabel-root': {
    color: theme.palette.primary.blue,
  },
  // Placeholder error
  '& .MuiFormLabel-root.Mui-error': {
    color: theme.palette.action.error,
  },
  // Border of textfield
  '& .MuiOutlinedInput-root': {
    overflow: 'hidden',
    '& fieldset': {
      border: '2px solid',
      borderColor: theme.palette.primary.blue,
      borderRadius: '5px',
    },
    '&:hover fieldset': {
      border: '2px solid',
      borderColor: theme.palette.primary.blue,
      borderRadius: '5px',
    },
    '&.Mui-focused fieldset': {
      color: theme.palette.primary.orange,
      border: '2px solid',
      borderColor: theme.palette.primary.orange,
      borderRadius: '5px',
    },
  },
  // '& .MuiOutlinedInput-input': {
  //   padding: '12px 16px',
  //   backgroundColor: 'white',
  // },
}));

export default CustomTextFieldV2;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  orangeTextTitle: {
    fontWeight: 700,
    color: theme.palette.primary.orange,
    marginBottom: '25px',
  },
  blueTextBold: {
    color: theme.palette.primary.blue,
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: '10px',
  },
  reasonOrangeText: {
    color: theme.palette.primary.orange,
    fontWeight: 600,
  },
  blueTextNormal: {
    color: theme.palette.primary.blue,
  },
  reasonContainer: {
    textAlign: 'center',
  },
  blueButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.blue,
    fontWeight: 'bold',
  },
  orangeButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.orange,
    fontWeight: 'bold',
  },
  errorPersistTypography: {
    fontWeight: 400,
    fontSize: '20px',
    color: theme.palette.primary.blue,
  },
}));

export default useStyles;

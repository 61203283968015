/* eslint-disable import/prefer-default-export */
import axios from '../../utils/axios';

const getCustomerByRut = async (rut, clientId) => {
  const response = await axios.get(`/api/customer/${rut}?clientId=${clientId}`);
  return response.data;
};

const createCustomer = async (customer) => {
  const response = await axios.post('/api/customer/create-new-customer', customer);
  return response.data;
};

export {
  getCustomerByRut,
  createCustomer,
};

export const RESET_CART = '@cart/reset-cart';
export const ADD_PRODUCT_CART = '@cart/add-product-cart';
export const REMOVE_PRODUCT_CART = '@cart/remove-product-cart';
export const DELETE_PRODUCT_CART = '@cart/delete-product-cart';
export const RESET_PAYMENTS_CART = '@cart/reset-payments-cart';
export const ADD_PAYMENTS_CART = '@cart/add-payments-cart';
export const UPDATE_PAYMENTS_CART = '@cart/update-payments-cart';
export const RESET_RESERVED_STOCK_CART = '@cart/reset-reserved-stock-cart';
export const ADD_RESERVED_STOCK_CART = '@cart/add-reserved-stock-cart';
export const UPDATE_CUSTOM_PRICE_CART = '@cart/update-custom-price-cart';
export const RESET_TOTAL_CART_AMOUNT = '@cart/reset_total_cart_amount';
export const SET_TOTAL_CART_AMOUNT = '@cart/set_total_cart_amount';
export const SET_LOADING_SALE_CART = '@cart/set_loading_sale_cart';

export function resetCart() {
  return {
    type: RESET_CART,
  };
}

export function addProductCart(payload) {
  return {
    type: ADD_PRODUCT_CART,
    payload,
  };
}

export function removeProductCart(payload) {
  return {
    type: REMOVE_PRODUCT_CART,
    payload,
  };
}

export function deleteProductCart(payload) {
  return {
    type: DELETE_PRODUCT_CART,
    payload,
  };
}

export function resetPaymentsCart() {
  return {
    type: RESET_PAYMENTS_CART,
  };
}

export function addPaymentsCart(payload) {
  return {
    type: ADD_PAYMENTS_CART,
    payload,
  };
}

export function updatePaymentsCart(payload) {
  return {
    type: UPDATE_PAYMENTS_CART,
    payload,
  };
}

export function resetReservedStock() {
  return {
    type: RESET_RESERVED_STOCK_CART,
  };
}

export function addReserveStock(payload) {
  return {
    type: ADD_RESERVED_STOCK_CART,
    payload,
  };
}

export function updateCustomPriceCart(payload) {
  return {
    type: UPDATE_CUSTOM_PRICE_CART,
    payload,
  };
}

export function resetTotalCartAmount() {
  return {
    type: RESET_TOTAL_CART_AMOUNT,
  };
}

export function setTotalCartAmount(payload) {
  return {
    type: SET_TOTAL_CART_AMOUNT,
    payload,
  };
}

export function setLoadingSaleCart(payload) {
  return {
    type: SET_LOADING_SALE_CART,
    payload,
  };
}

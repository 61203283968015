import React from 'react';

import SuccessView from '../../components/Success';

function SuccessReturnsFinalView() {
  return (
    <SuccessView
    tabTitle={'Devolución exitosa'}
    firstTitle={'Devolución'}
    secondTitle={'Exitosa'}
    documentType={'Nota de crédito'}
    />
  );
}

export default SuccessReturnsFinalView;

import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { format } from 'rut.js';
import CustomButton from '../../../components/Material/CustomButton';
import CustomTitleTypography from '../../../components/Material/CustomTitlesTypography';
import { formatDate } from '../../../utils/date';
import thousandSeparator from '../../../utils/thousandSeparator';
import useStyles from '../styles';

function CreditSalePaymentAction({ lastCreditTaken }) {
  const history = useHistory();
  const classes = useStyles();

  const { selectedCustomerDebt } = useSelector((state) => state.customer);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleGoPay = () => {
    history.push('/store-options/credit-sale/payments');
  };

  return (

    <Box
      padding='20px 24px 0px 24px'
      boxShadow='0px -3px 3px rgba(0, 0, 0, 0.15)'
    >
      <Box>
        <Typography
          className={classes.blueTypography}
        >
          {`Rut: ${format(selectedCustomerDebt.rut)}`}
        </Typography>
        <Typography
          className={classes.blueTypography}
        >
          {lastCreditTaken && <>
            {`Fecha último fiado pendiente: ${formatDate(selectedCustomerDebt.lastCredit)}`}
          </>

          }
          {!lastCreditTaken && <>
            El cliente no tiene fiados pendientes
          </>
          }
        </Typography>
        <CustomTitleTypography
          firstHighlight='Deuda total: '
          secondHighlight={`$ ${thousandSeparator(selectedCustomerDebt.totalDebt - Number(selectedCustomerDebt.totalPayedAmount || 0), '.')}`}
          textAlign='left'
        />
      </Box>
      <Box
        mt={2}
      >
        <Box>
          <CustomButton
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            buttoncolor='orange'
            onClick={handleGoPay}
          >
            Ir a pagar
          </CustomButton>
        </Box>
        <Box
          mt={2}
        >
          <CustomButton
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            buttoncolor='blue'
            onClick={handleGoBack}
          >
            Atrás
          </CustomButton>
        </Box>

      </Box>
    </Box>
  );
}

export default CreditSalePaymentAction;

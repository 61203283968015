import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clean } from 'rut.js';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
  Edit2 as WriteIcon,
  Search as SearchIcon,
} from 'react-feather';
import { useHistory } from 'react-router';

import * as Yup from 'yup';
import { Formik } from 'formik';
import useStyles from '../styles';

import CustomTextField from '../../../components/Material/CustomTextfieldV2';
import { getCompaniesPerPage, getCompanyByIdentificacionNumber, postNewCompany } from '../../../requests/api/companies';
import handleApiResponse from '../../../utils/handleApiResponse';
import { editCustomer } from '../../../actions/customerActions';
import CustomButton from '../../../components/Material/CustomButton';

function BillForm() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { clientId } = useSelector((state) => state.account.user);
  const { customer } = useSelector((state) => state.customer);

  const [companies, setCompanies] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [searchByIdNumFlag, setSearchByIdNumFlag] = useState(false);

  const handleSearchCompanyByIdNum = async (searchIdNum) => {
    if (searchIdNum.trim() === '') {
      return;
    }

    try {
      const response = await getCompanyByIdentificacionNumber(clean(searchIdNum));
      if (response.company) {
        setSelectedCompany(response.company);
      }
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  useEffect(() => {
    const getAllCompaniesFromCustomer = async () => {
      try {
        const response = await getCompaniesPerPage({
          limit: '', page: '', searchValue: '', clientId, customerId: customer.id,
        });
        setCompanies(response.companies);
        if (response.companies.length > 0) {
          setSearchByIdNumFlag(false);
        } else {
          setSearchByIdNumFlag(true);
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    if (customer && customer.id) {
      getAllCompaniesFromCustomer();
    } else {
      setSearchByIdNumFlag(true);
    }
  }, []);

  return (
    <Formik
      initialValues={{
        name: '',
        identificationNumber: '',
        activity: '',
        address: '',
        commune: '',
        city: '',
        country: '',
      }}
      validationSchema={Yup.object().shape({
        identificationNumber: Yup.string().max(255).required('Debe ingresar el rut'),
        name: Yup.string().max(255).required('Debe ingresar el nombre'),
        activity: Yup.string().max(255).required('Debe ingresar el giro'),
        address: Yup.string().max(255).required('Debe ingresar la dirección'),
        commune: Yup.string().max(255).required('Debe ingresar la comuna'),
        city: Yup.string().max(255).required('Debe ingresar la ciudad'),
        country: Yup.string().max(255).required('Debe ingresar el país'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const data = {
          identificationNumber: values.identificationNumber,
          name: values.name,
          activity: values.activity,
          address: values.address,
          commune: values.commune,
          city: values.city,
          country: values.country,
        };

        if (!selectedCompany) {
          try {
            const response = await postNewCompany({
              ...data,
              customerId: (customer && customer.id) || 0,
              clientId,
            });
            dispatch(editCustomer({ billingInfo: response.company }));
            setStatus({ success: true });
            setSubmitting(false);
            history.push('/cart/payments');
          } catch (error) {
            handleApiResponse(enqueueSnackbar, error, false);
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        } else {
          dispatch(editCustomer({ billingInfo: data }));
          setStatus({ success: true });
          setSubmitting(false);
          history.push('/cart/payments');
        }
      }
      }
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {searchByIdNumFlag
                && <>
                  <InputLabel
                    className={classes.inputLabel}
                  >
                    RUT
                  </InputLabel>
                  <Box
                    display='flex'
                    alignItems='center'
                  >
                    <CustomTextField
                      fullWidth
                      color="secondary"
                      placeholder="11.111.111-1"
                      name="identificationNumber"
                      helperText={touched.identificationNumber && errors.identificationNumber}
                      error={Boolean(touched.identificationNumber && errors.identificationNumber)}
                      onChange={handleChange}
                      value={selectedCompany
                        ? selectedCompany.identificationNumber
                        : values.identificationNumber
                      }
                      variant="outlined"
                    />
                    <Button
                      variant="contained"
                      onClick={() => handleSearchCompanyByIdNum(values.identificationNumber)}
                      className={classes.searchButton}
                    >
                      <SearchIcon />
                    </Button>
                  </Box>
                </>}
              {!searchByIdNumFlag
                && <>
                  <InputLabel
                    className={classes.inputLabel}
                  >
                    Rut
                  </InputLabel>
                  <Box
                    display='flex'
                    alignItems='center'
                  >
                    <CustomTextField
                      fullWidth
                      color="secondary"
                      select
                      placeholder="11.111.111-1"
                      name="identificationNumber"
                      onChange={handleChange}
                      value={selectedCompany
                        ? selectedCompany.identificationNumber
                        : values.identificationNumber}
                      variant="outlined"
                    >
                      {companies ? companies.map((option) => (
                        <MenuItem
                          key={option.company.id}
                          value={option.company.identificationNumber}
                          onClick={() => {
                            setSelectedCompany(option.company);
                            setFieldValue('name', option.company.name);
                            setFieldValue('activity', option.company.activity);
                            setFieldValue('address', option.company.address);
                            setFieldValue('city', option.company.city);
                            setFieldValue('commune', option.company.commune);
                            setFieldValue('country', option.company.country);
                          }}
                        >
                          {option.company.identificationNumber}
                        </MenuItem>
                      )) : []}
                    </CustomTextField>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() => setSearchByIdNumFlag(true)}
                      className={classes.searchButton}
                    >
                      <WriteIcon />
                    </Button>
                  </Box>
                </>}
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                className={classes.inputLabel}
              >
                Razón Social
              </InputLabel>
              <CustomTextField
                helperText={touched.name && errors.name}
                error={Boolean(touched.name && errors.name)}
                placeholder="Razón Social"
                fullWidth
                color="secondary"
                name="name"
                onChange={handleChange}
                type="text"
                value={selectedCompany ? selectedCompany.name : values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                className={classes.inputLabel}
              >
                Giro
              </InputLabel>
              <CustomTextField
                helperText={touched.activity && errors.activity}
                error={Boolean(touched.activity && errors.activity)}
                fullWidth
                placeholder="Giro"
                color="secondary"
                name="activity"
                onChange={handleChange}
                type="text"
                value={selectedCompany ? selectedCompany.activity : values.activity}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                className={classes.inputLabel}
              >
                Dirección
              </InputLabel>
              <CustomTextField
                helperText={touched.address && errors.address}
                error={Boolean(touched.address && errors.address)}
                fullWidth
                placeholder="Dirección"
                color="secondary"
                name="address"
                type="text"
                onChange={handleChange}
                value={selectedCompany ? selectedCompany.address : values.address}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                className={classes.inputLabel}
              >
                Comuna
              </InputLabel>
              <CustomTextField
                helperText={touched.commune && errors.commune}
                error={Boolean(touched.commune && errors.commune)}
                fullWidth
                color="secondary"
                placeholder="Comuna"
                name="commune"
                onChange={handleChange}
                type="text"
                value={selectedCompany ? selectedCompany.commune : values.commune}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                className={classes.inputLabel}
              >
                Ciudad
              </InputLabel>
              <CustomTextField
                helperText={touched.city && errors.city}
                error={Boolean(touched.city && errors.city)}
                fullWidth
                placeholder="Ciudad"
                color="secondary"
                name="city"
                onChange={handleChange}
                type="text"
                value={selectedCompany ? selectedCompany.city : values.city}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                className={classes.inputLabel}
              >
                País
              </InputLabel>
              <CustomTextField
                helperText={touched.country && errors.country}
                error={Boolean(touched.country && errors.country)}
                fullWidth
                placeholder="País"
                color="secondary"
                name="country"
                onChange={handleChange}
                type="text"
                value={selectedCompany ? selectedCompany.country : values.country}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Box
            display='flex'
            justifyContent='center'
            mt={2}
          >
            <CustomButton
              type="submit"
              variant="contained"
              color='primary'
              disabled={isSubmitting}
              buttoncolor='blue'
            >
              Guardar
            </CustomButton>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default BillForm;

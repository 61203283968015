import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chevronLeftSymbol: {
    color: theme.palette.primary.blue,
  },
  orangeTextTitle: {
    color: theme.palette.primary.orange,
    fontWeight: 700,
    fontSize: '22px',
  },
  blueButton: {
    backgroundColor: theme.palette.primary.blue,
    fontWeight: 'bold',
    color: 'white',
    width: '100%',
    maxWidth: '400px',
    marginTop: '16px',
  },
  regularBlueText: {
    color: theme.palette.primary.blue,
    fontSize: theme.fontSizes.bodyText,
  },
  blueText: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
    fontSize: theme.fontSizes.bodyText,
  },
  blueSearchIcon: {
    color: theme.palette.primary.blue,
    fontSize: '32px',
  },
  datePicker: {
    '& .MuiInputBase-input': {
      padding: '12px 16px',
      fontSize: theme.fontSizes.bodyText,
      fontWeight: 400,
      color: theme.palette.primary.blue,
      backgroundColor: 'white',
    },
    // TextField
    '& .MuiTextField-root': {
      margin: '0px 5px 0px 0px',
    },
    // Placeholder
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.blue,
    },
    // Placeholder error
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.action.error,
    },
    // Border of textfield
    '& .MuiOutlinedInput-root': {
      overflow: 'hidden',
      '& fieldset': {
        border: '2px solid',
        borderColor: theme.palette.primary.blue,
        borderRadius: '5px',
      },
      '&:hover fieldset': {
        border: '2px solid',
        borderColor: theme.palette.primary.blue,
        borderRadius: '5px',
      },
      '&.Mui-focused fieldset': {
        color: theme.palette.primary.orange,
        border: '2px solid',
        borderColor: theme.palette.primary.orange,
        borderRadius: '5px',
      },
    },
  },
  tableHeader: {
    height: '40px',
    fontSize: theme.fontSizes.tableHeader,
  },
  tableHeaderMaxWidth: {
    maxWidth: 'calc(100vh/4)',
  },
  tableHeaderColor: {
    backgroundColor: '#f1f1f1',
  },
  tableHeaderTableCellLeft: {
    padding: '0px 8px 0px 16px',
    fontSize: theme.fontSizes.tableHeader,
    fontWeight: 700,
    color: theme.palette.primary.blue,
  },
  tableHeaderTableCellRight: {
    padding: '0px 16px 0px 8px',
    textAlign: 'right',
    color: theme.palette.primary.blue,
    fontSize: theme.fontSizes.tableHeader,
  },
  tableHeaderTableCell: {
    padding: '0px 8px',
    textAlign: 'center',
    color: theme.palette.primary.blue,
    fontSize: theme.fontSizes.tableHeader,
  },
  tableCellBodyLeft: {
    padding: '24px 8px 24px 16px',
    color: theme.palette.primary.blue,
    fontSize: theme.fontSizes.tableCell,
  },
  tableCellBody: {
    padding: '24px 8px 24px 8px',
    fontSize: theme.fontSizes.tableCell,
    textAlign: 'center',
    color: theme.palette.primary.blue,
  },
  tableCellBodyRight: {
    padding: '24px 16px 24px 8px',
    textAlign: 'center',
    fontSize: theme.fontSizes.tableCell,
  },
  tableCellBodyTypography: {
    fontSize: theme.fontSizes.tableCell,
    fontWeight: 700,
  },
  tableCellBodyTypographyRight: {
    fontSize: theme.fontSizes.tableCell,
    fontWeight: 700,
    textAlign: 'right',
  },
  boldText: {
    fontWeight: 700,
  },
  chevronRightSymbol: {
    fontSize: '25px',
    color: theme.palette.primary.blue,
    fontWeight: 700,
    padding: '0px',
  },
  marginDivider: {
    border: '0.5px solid',
    borderColor: theme.palette.primary.blue,
    margin: '25px 0px 0px 0px',
  },
  noPadding: {
    padding: '0px',
  },
  elevatedBox: {
    display: 'flex',
    boxShadow: '0px 15px 10px -15px rgba(0, 0, 0, 0.25)',
    padding: '16px 0px 16px 24px',
  },
  cardTitleTypography: {
    color: theme.palette.primary.blue,
    fontSize: '16px',
    fontWeight: 700,
  },
  cardDetailTypography: {
    color: theme.palette.primary.blue,
    fontSize: theme.fontSizes.bodyText,
    fontWeight: 700,
  },
  blueTypography: {
    fontSize: theme.fontSizes.bodyText,
    fontWeight: 400,
    color: theme.palette.primary.blue,
  },
  blueTextTypo: {
    color: theme.palette.primary.blue,
  },
  orangeTextTypo: {
    color: theme.palette.primary.orange,
  },
}));

export default useStyles;

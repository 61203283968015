import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chevronLeftSymbol: {
    color: theme.palette.primary.blue,
  },
  orangeTextTitle: {
    color: theme.palette.primary.orange,
    fontWeight: 700,
    fontSize: '22px',
  },
  blueButton: {
    backgroundColor: theme.palette.primary.blue,
    fontWeight: 'bold',
    color: 'white',
    width: '100%',
    maxWidth: '400px',
    marginTop: '16px',
  },
  boldBlueText: {
    color: theme.palette.primary.blue,
    fontSize: '16px',
    fontWeight: 700,
  },
  boldOrangeText: {
    color: theme.palette.primary.orange,
    fontSize: '16px',
    fontWeight: 700,
  },
  blueText: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
    fontSize: theme.fontSizes.bodyText,
  },
  radioButtonLabel: {
    color: theme.palette.primary.blue,
  },
  tableCellBodyLeft: {
    padding: '24px 8px 24px 16px',
  },
  tableCellBody: {
    padding: '24px 8px 24px 8px',
    textAlign: 'center',
    fontSize: theme.fontSizes.tableCell,
  },
  tableCellBodyRight: {
    padding: '24px 16px 24px 8px',
    textAlign: 'center',
    fontSize: theme.fontSizes.tableCell,
  },
  boldTitle: {
    fontSize: theme.fontSizes.bodyText,
    fontWeight: 700,
    color: theme.palette.primary.blue,
  },
  regularText: {
    fontSize: theme.fontSizes.bodyText,
    color: theme.palette.primary.blue,
  },
  minusSymbol: {
    margin: '5px 10px 0px 0px',
    color: theme.palette.primary.orange,
  },
  plusSymbol: {
    margin: '5px 0px 0px 10px',
    color: theme.palette.primary.orange,
  },
  boldBlueNumber: {
    fontSize: '25px',
    color: theme.palette.primary.blue,
    fontWeight: 700,
  },
  headerTable: {
    height: '40px',
    textAlign: 'center',
    padding: '0px',
    borderBottom: 'none',
    backgroundColor: '#f1f1f1',
  },
  orangeText: {
    color: theme.palette.primary.orange,
    fontWeight: 700,
    fontSize: '22px',
  },
  dividerStyle: {
    border: '1px solid',
    borderColor: 'gainsboro',
    width: '100%',
    margin: '10px 0px',
  },
  tableHeader: {
    height: '80px',
    fontSize: theme.fontSizes.tableHeader,
  },
  tableHeaderMaxWidth: {
    maxWidth: 'calc(100vh/4)',
  },
  tableHeaderColor: {
    backgroundColor: '#f1f1f1',
  },
  tableHeaderTableCellLeft: {
    padding: '0px 8px 0px 16px',
    fontSize: theme.fontSizes.tableHeader,
    fontWeight: 700,
    color: theme.palette.primary.blue,
  },
  tableHeaderTableCellRight: {
    fontSize: theme.fontSizes.tableHeader,
    padding: '0px 16px 0px 8px',
    textAlign: 'center',
  },
  tableHeaderTableCell: {
    padding: '0px 8px',
    textAlign: 'center',
    fontSize: theme.fontSizes.tableHeader,
  },
}));

export default useStyles;

import { TextField, styled } from '@material-ui/core';

const CustomTextField = styled(TextField)(({ theme }) => ({
  // Input component
  '& .MuiInputBase-input': {
    padding: '16px',
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.primary.blue,
  },
  // Placeholder
  '& .MuiFormLabel-root': {
    color: theme.palette.primary.blue,
  },
  // Placeholder error
  '& .MuiFormLabel-root.Mui-error': {
    color: theme.palette.action.error,
  },
  // Placeholder on Top when focused
  '& label.Mui-focused': {
    color: theme.palette.primary.orange,
  },
  // Border of textfield
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '2px solid',
      borderColor: theme.palette.primary.blue,
      borderRadius: '5px',
    },
    '&:hover fieldset': {
      border: '2px solid',
      borderColor: theme.palette.primary.blue,
      borderRadius: '5px',
    },
    '&.Mui-focused fieldset': {
      color: theme.palette.primary.orange,
      border: '2px solid',
      borderColor: theme.palette.primary.orange,
      borderRadius: '5px',
    },
  },
}));

export default CustomTextField;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
    '& a': {
      textAlign: 'center',
      display: 'block',
      color: theme.palette.primary.white,
      textDecoration: 'none',
      fontSize: '16px',
    },
  },
  loadingPage: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '300px',
    height: '66px',
  },
  blueTextTitle: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
  },
  orangeTextTitle: {
    color: theme.palette.primary.orange,
    fontWeight: 700,
  },
  circle: {
    strokeLinecap: 'round',
  },
  bottom: {
    color: 'lightblue',
  },
  top: {
    color: theme.palette.primary.blue,
    animationDuration: '1050ms',
    position: 'absolute',
    left: 0,
  },
}));

export default useStyles;

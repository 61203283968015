import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box, IconButton, TextField, Typography,
} from '@material-ui/core';
import { Check, Edit2, RotateCcw } from 'react-feather';
import thousandSeparator from '../../utils/thousandSeparator';
import useStyles from './styles';

const PriceControl = ({
  price, discountPrice, setAllProducts, allProducts, product,
}) => {
  const classes = useStyles();
  const changePrice = useSelector((state) => state.session.moduleInformation.priceChange);
  const { productCart } = useSelector((state) => state.cart);
  const [edit, setEdit] = useState(false);
  const [customPrice, setCustomPrice] = useState('');

  const removeCustomPrice = () => {
    setAllProducts(
      allProducts.map((p) => {
        if (p.productId === product.productId) {
          const newProduct = { ...p };
          newProduct.customPrice = null;
          return newProduct;
        }
        return p;
      }),
    );
  };

  const applyCustomPrice = () => {
    setAllProducts(
      allProducts.map((p) => {
        if (p.productId === product.productId) {
          const newProduct = { ...p };
          newProduct.customPrice = customPrice;
          return newProduct;
        }
        return p;
      }),
    );
  };

  const setPrice = () => {
    if (product.customPrice && !edit) {
      return (<Box sx={{ flexWrap: 'wrap', alignContent: 'center' }}>
        <Typography
          variant='body1'
          component='span'
          className={classes.customPrice}
        >
          {`$ ${thousandSeparator(product.customPrice, '.')}`}
        </Typography>
        {!productCart && <>
          <IconButton
            size='small'
            onClick={() => setEdit(true)}>
            <Edit2 className={classes.orangeIcon} />
          </IconButton>
          <IconButton
            size='small'
            onClick={() => {
              removeCustomPrice();
              setEdit(false);
            }}>
            <RotateCcw />
          </IconButton>
        </>}
        {productCart
          && Object.keys(productCart) === 0
          && <>
            <IconButton
              size='small'
              onClick={() => setEdit(true)}>
              <Edit2 className={classes.orangeIcon} />
            </IconButton>
            <IconButton
              size='small'
              onClick={() => {
                removeCustomPrice();
                setEdit(false);
              }}>
              <RotateCcw />
            </IconButton>
          </>
        }
        {(productCart
          && !productCart[product.productId]
        )
          && <>
            <IconButton
              size='small'
              onClick={() => setEdit(true)}>
              <Edit2 className={classes.orangeIcon} />
            </IconButton>
            <IconButton
              size='small'
              onClick={() => {
                removeCustomPrice();
                setEdit(false);
              }}>
              <RotateCcw />
            </IconButton>
          </>
        }
      </Box>);
    }
    return (<Box sx={{ alignContent: 'center' }} >
      <Typography
        variant='body1'
        component='span'
        className={discountPrice ? classes.blueTextUnderlined : classes.blueTextPrice}
      >
        {`$ ${thousandSeparator(price, '.')}`}
      </Typography>
      {discountPrice && <Typography
        variant='body1'
        component='span'
        className={classes.orangeTextPrice}
      >
        {`$ ${thousandSeparator(discountPrice, '.')}`}
      </Typography>}
      {changePrice && <IconButton
        size='small'
        onClick={() => setEdit(true)}>
        <Edit2 className={classes.orangeIcon} />
      </IconButton>}
    </Box>);
  };

  return (<Box sx={{ display: 'inline-flex', width: '100%' }}>
    {(edit) ? (<>
      <TextField
        // type='number'
        className={classes.priceTextField}
        value={customPrice}
        InputProps={{
          startAdornment:
            <Typography className={classes.bluePriceTextTitle}>
              $
            </Typography>,
          endAdornment: edit && <IconButton
            onClick={() => {
              applyCustomPrice();
              setEdit(false);
            }}>
            <Check className={classes.orangeIcon} />
          </IconButton>,
          inputProps: {
            min: 0,
          },
        }}
        onChange={(e) => {
          const { value } = e.target;
          const number = Number(value);
          if (value === '') {
            setCustomPrice('');
            return;
          }
          setCustomPrice(number);
        }}
      />
    </>) : setPrice()}
  </Box>);
};

export default PriceControl;

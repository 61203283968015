import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import {
  Box, Table, TableBody, TableCell, TableHead, TableRow, SvgIcon, Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
  Plus as PlusIcon,
  Minus as MinusIcon,
  Trash2 as TrashIcon,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';

import useStyles from '../styles';
import thousandSeparator from '../../../utils/thousandSeparator';
import { addProductCart, deleteProductCart, removeProductCart } from '../../../actions/cartActions';

function DisplayItems() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { productCart } = useSelector((state) => state.cart);

  const handleAddOneItem = (productId, infoProduct) => {
    if (infoProduct.availableStock <= infoProduct.quantityInCart) {
      enqueueSnackbar('No puedes agregar más items de los que estan en stock o en tu carro', {
        variant: 'error',
      });
    } else {
      dispatch(addProductCart({ ...infoProduct, productId, quantityToAdd: 1 }));
    }
  };

  const handleRemoveOneItem = (productId, infoProduct) => {
    dispatch(removeProductCart({ ...infoProduct, productId, quantityToRemove: 1 }));
  };

  const handleDeleteItem = (productId) => {
    dispatch(deleteProductCart({ productId }));
  };

  const normalPrice = (productId) => (<>
  {(!productCart[productId].discountPriceListPrice
    || (productCart[productId].discountPriceListPrice
      >= productCart[productId].priceListPrice))
    && <Typography
      variant='body1'
      component='span'
      className={classes.blueText}
    >
      {`$ ${thousandSeparator(productCart[productId].priceListPrice, '.')}`}
    </Typography>}
    {(productCart[productId].discountPriceListPrice
    && (productCart[productId].discountPriceListPrice
      < productCart[productId].priceListPrice))
    && <Box
      display='flex'
      flexDirection='column'
    >
      <Typography
        variant='body1'
        component='span'
        className={classes.blueTextUnderlined}
      >
        {`$ ${thousandSeparator(productCart[productId].priceListPrice, '.')}`}
      </Typography>

      <Typography
        variant='body1'
        component='span'
        className={classes.orangeText}
      >
        {`$ ${thousandSeparator(productCart[productId].discountPriceListPrice, '.')}`}
      </Typography>
    </Box>}
  </>);

  const customPrice = (productId) => (<Box
    display='flex'
    flexDirection='column'
  >
    <Typography
      variant='body1'
      component='span'
      className={classes.blueTextUnderlined}
    >
      {`$ ${thousandSeparator(productCart[productId].priceListPrice, '.')}`}
    </Typography>

    <Typography
      variant='body1'
      component='span'
      className={classes.orangeText}
    >
      {`$ ${thousandSeparator(productCart[productId].customPrice, '.')}`}
    </Typography>
  </Box>);

  return (
    <PerfectScrollbar >
      <Box
        className={classes.displayItemsWrapper}
      >
        <Table stickyHeader>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={clsx(
                classes.tableHeaderTableCellLeft,
                classes.tableHeaderMaxWidth,
                classes.tableHeaderColor,
              )}>
                Item
              </TableCell>
              <TableCell className={
                clsx(
                  classes.tableHeaderMaxWidth,
                  classes.regularText,
                  classes.tableHeaderTableCell,
                  classes.tableHeaderColor,
                )}>
                Cantidad
              </TableCell>
              <TableCell className={
                clsx(
                  classes.tableHeaderMaxWidth,
                  classes.regularText,
                  classes.tableHeaderTableCell,
                  classes.tableHeaderColor,
                )}>
                Precio
              </TableCell>
              <TableCell className={
                clsx(
                  classes.tableHeaderMaxWidth,
                  classes.regularText,
                  classes.tableHeaderTableCellRight,
                  classes.tableHeaderColor,
                )}>
                Subtotal
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productCart && Object.keys(productCart).map((productId) => {
              const preNormalPrice = (productCart[productId].discountPriceListPrice
                && productCart[productId].discountPriceListPrice
                < productCart[productId].priceListPrice)
                ? productCart[productId].discountPriceListPrice
                : productCart[productId].priceListPrice;

              const preCustomPrice = productCart[productId].customPrice;

              const relevantPrice = preCustomPrice || preNormalPrice;

              return (
                <TableRow key={productId}>
                  <TableCell className={classes.tableCellBodyLeft}>
                    <Typography
                      variant='h4'
                      className={classes.boldTitle}
                    >
                      {productCart[productId].name}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    <Box
                      display='flex'
                      justifyContent='center'
                    >
                      {productCart[productId].quantityInCart > 1
                        && <SvgIcon
                          className={classes.minusSymbol}
                          onClick={() => handleRemoveOneItem(productId, productCart[productId])}
                        >
                          <MinusIcon />
                        </SvgIcon>
                      }
                      {productCart[productId].quantityInCart === 1
                        && <SvgIcon
                          className={classes.minusSymbol}
                          onClick={() => handleDeleteItem(productId)}
                        >
                          <TrashIcon />
                        </SvgIcon>

                      }
                      <Box className={classes.boldBlueNumber}>
                        {productCart[productId].quantityInCart}
                      </Box>
                      <SvgIcon
                        className={classes.plusSymbol}
                        onClick={() => handleAddOneItem(productId, productCart[productId])}
                      >
                        <PlusIcon />
                      </SvgIcon>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    { !productCart[productId].customPrice
                      ? normalPrice(productId) : customPrice(productId)}
                  </TableCell>
                  <TableCell className={clsx(classes.tableCellCenter, classes.tableCellBodyRight)}>
                    <Box className={classes.blueText}>
                      {`$ ${thousandSeparator(relevantPrice * productCart[productId].quantityInCart, '.')}`}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
  );
}

export default DisplayItems;

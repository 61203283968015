/* eslint-disable import/prefer-default-export */
import axios from '../../utils/axios';

const getModuleInformationByClient = async ({ clientId }) => {
  const response = await axios.get(`/api/module/client/${clientId}`);
  return response.data;
};

export {
  getModuleInformationByClient,
};

import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import useStyles from './styles';
import { setTimesError } from '../../actions/sessionActions';
import CustomButton from '../Material/CustomButton';

function Error({ title, subTitle }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { timesError } = useSelector((state) => state.session);

  const handleGoBack = () => {
    dispatch(setTimesError(timesError + 1));
    history.goBack();
  };

  const handleGoHome = () => {
    history.replace('/store-options');
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      flex={1}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        flex={1}
      >
        <Typography
          variant='h2'
          className={classes.orangeTextTitle}
        >
          {title}
        </Typography>
        <Typography
          variant='h4'
          className={classes.blueTextBold}
        >
          {subTitle}
        </Typography>
        <Box className={classes.reasonContainer}>
          <Typography
            component='span'
            variant='body1'
            className={classes.reasonOrangeText}
          >
            {'Motivo: '}
          </Typography>
          <Typography
            component='span'
            variant='body1'
            className={classes.blueTextNormal}
          >
            {location.state.errorMessage || 'Error no identificado'}
          </Typography>
        </Box>
        <Box mt={4}>
          <CustomButton
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleGoBack}
            buttoncolor='blue'
          >
            Volver a intentar
          </CustomButton>
        </Box>
      </Box>
      {timesError > 0
        ? (<Box>
          <Box
            display='flex'
            alignItems='center'
            flexDirection='column'
          >
            <Typography
              className={classes.errorPersistTypography}
            >
              ¿El error persiste?
            </Typography>
            <CustomButton
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleGoHome}
            >
              Notificar y Volver al Home
            </CustomButton>
          </Box>
        </Box>)
        : null}

    </Box>
  );
}

Error.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  errorMessage: PropTypes.string,
};

Error.defaultProps = {
  title: '',
  subTitle: '',
  errorMessage: '',
};

export default Error;

import { useSelector } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

function AuthComponent({
  children, route, selector, redirectValidator,
}) {
  let validator = true;
  if (redirectValidator) {
    validator = useSelector(selector);
  }
  const relevantAtr = useSelector(selector);

  if (validator) {
    if (!relevantAtr) {
      return <Redirect to={route} />;
    }
  }

  return children;
}

AuthComponent.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.string.isRequired,
  selector: PropTypes.func.isRequired,
  redirectValidator: PropTypes.func,
};

export default AuthComponent;

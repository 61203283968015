import React from 'react';
import {
  Box,
} from '@material-ui/core';
import Page from '../../components/Layout/Page';

import useStyles from './styles';
import Header from './Components/Header';
import DisplayItems from './Components/DisplayItems';
import TotalsToPay from './Components/TotalsToPay';
import CustomContainer from '../../components/Material/CustomContainer';

function CartView() {
  const classes = useStyles();

  return (
    <Page
      title="Carro de compras"
    >
      <CustomContainer
        nopadding="true"
        maxWidth="md"
        className={classes.container}
      >
        <Box
          width='100%'
          flex={1}
        >
          <Box
            padding='0px 24px'
          >
            <Header />
          </Box>
          <DisplayItems />
        </Box>
        <TotalsToPay />
      </CustomContainer>
    </Page>
  );
}

export default CartView;

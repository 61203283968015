import React from 'react';
import Page from '../Layout/Page';

import Success from './Success';
import CustomContainer from '../Material/CustomContainer';

function SuccessView({
  tabTitle,
  firstTitle,
  secondTitle,
  documentType,
}) {
  return (
    <Page
      title={tabTitle}
    >
      <CustomContainer maxWidth="md">
        <Success
          firstTitle={firstTitle}
          secondTitle={secondTitle}
          documentType={documentType}
        />
      </CustomContainer>
    </Page>
  );
}

export default SuccessView;

const ORDER_PENDING = 'pendiente'; // orden pendiente de pago
const ORDER_APPROVED = 'aprobada'; // orden con pago aprobado
const ORDER_CANCELED = 'cancelada'; // orden cancelada por cliente
const ORDER_SAVED = 'guardada'; // orden guardada para despues
const ORDER_REJECTED = 'rechazada'; // orden rechazada por pago
const PAYMENT_PENDING = 'pendiente'; // pago pendiente
const PAYMENT_APPROVED = 'aprobado'; // pago aprobado
const PAYMENT_CANCELED = 'cancelado'; // pago cancelado
const PAYMENT_REJECTED = 'rechazado'; // pago rechazado

module.exports = {
  ORDER_PENDING,
  ORDER_APPROVED,
  ORDER_CANCELED,
  ORDER_SAVED,
  ORDER_REJECTED,
  PAYMENT_PENDING,
  PAYMENT_APPROVED,
  PAYMENT_CANCELED,
  PAYMENT_REJECTED,
};

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  blueText: {
    color: theme.palette.primary.blue,
    fontWeight: 700,
  },
  orangeText: {
    color: theme.palette.primary.orange,
    fontWeight: 700,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  orangeButton: {
    backgroundColor: theme.palette.primary.orange,
    fontWeight: 'bold',
    margin: '0px 5px 20px 5px',
    maxWidth: '500px',
  },
  blueButton: {
    backgroundColor: theme.palette.primary.blue,
    fontWeight: 'bold',
    margin: '0px 5px 20px 5px',
    maxWidth: '500px',
  },
  chevronLeftSymbol: {
    color: theme.palette.primary.blue,
  },
  marginDivider: {
    border: '0.5px solid',
    borderColor: theme.palette.primary.blue,
    margin: '24px 0px',
  },
  inputLabel: {
    fontWeight: 700,
    fontSize: theme.fontSizes.bodyText,
    color: theme.palette.primary.blue,
    marginBottom: '8px',
  },
  radioButtonLabel: {
    color: theme.palette.primary.blue,
    fontSize: theme.fontSizes.bodyText,
  },
  datePicker: {
    '& .MuiInputBase-input': {
      padding: '12px 16px',
      fontSize: theme.fontSizes.bodyText,
      fontWeight: 400,
      color: theme.palette.primary.blue,
      backgroundColor: 'white',
    },
    // TextField
    '& .MuiTextField-root': {
      margin: '0px 5px 0px 0px',
    },
    // Placeholder
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.blue,
    },
    // Placeholder error
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.action.error,
    },
    // Border of textfield
    '& .MuiOutlinedInput-root': {
      overflow: 'hidden',
      '& fieldset': {
        border: '2px solid',
        borderColor: theme.palette.primary.blue,
        borderRadius: '5px',
      },
      '&:hover fieldset': {
        border: '2px solid',
        borderColor: theme.palette.primary.blue,
        borderRadius: '5px',
      },
      '&.Mui-focused fieldset': {
        color: theme.palette.primary.orange,
        border: '2px solid',
        borderColor: theme.palette.primary.orange,
        borderRadius: '5px',
      },
    },
  },
}));

export default useStyles;

import React from 'react';
import SuccessView from '../../components/Success';

function SuccessSaleFinalView() {
  return (
    <SuccessView
      tabTitle={'Venta exitosa'}
      firstTitle={'Venta'}
      secondTitle={'Exitosa'}
      documentType={'Boleta/Factura'}
    />
  );
}

export default SuccessSaleFinalView;

/* eslint-disable default-param-last */
/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import {
  RESET_PRODUCT_STOCK,
  ADD_PRODUCT_STOCK,
  SEARCH_QUERY_PRODUCTS,
  RESET_ALL_PRODUCT_STOCK,
  SET_ALL_PRODUCT_STOCK,
} from '../actions/productActions';

const initialState = {
  addStock: null,
  searchQuery: '',
  allProducts: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PRODUCT_STOCK: {
      return produce(state, (draft) => {
        draft.addStock = null;
      });
    }

    case ADD_PRODUCT_STOCK: {
      return produce(state, (draft) => {
        draft.addStock = action.payload;
      });
    }

    case SEARCH_QUERY_PRODUCTS: {
      return produce(state, (draft) => {
        draft.searchQuery = action.payload;
      });
    }

    case RESET_ALL_PRODUCT_STOCK: {
      return produce(state, (draft) => {
        draft.allProducts = null;
      });
    }

    case SET_ALL_PRODUCT_STOCK: {
      return produce(state, (draft) => {
        const prevAllProducts = state.allProducts ? [...state.allProducts] : [];
        draft.allProducts = [...prevAllProducts, action.payload];
      });
    }

    default: {
      return state;
    }
  }
};

export default productReducer;

import React, { useState } from 'react';
import {
  Box,
} from '@material-ui/core';
import Page from '../../components/Layout/Page';

import Header from './Components/Header';
import DisplayItems from './Components/DisplayItems';
import ReturnsTotal from './Components/ReturnsTotal';
import CustomizedSplashScreen from '../../components/Loading/CustomizedSplashScreen';
import CustomContainer from '../../components/Material/CustomContainer';

function ReturnsDetailView() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Page
      title="Detalle Devoluciones"
    >
      <CustomContainer
        nopadding="true"
        maxWidth="md"
      >
        {!isLoading
          && <>
            <Box
              width='100%'
              display='flex'
              flexDirection='column'
              flex='1'
            >
              <Box
                padding='0px 24px'
              >
                <Header />
              </Box>
              <DisplayItems />
            </Box>
            <ReturnsTotal
              setIsLoading={setIsLoading}
            />
          </>
        }
        {isLoading
          && <CustomizedSplashScreen
            tabTitle='Cargando...'
            firstTitle='Generando'
            secondTitle='Nota de Crédito'
          />}

      </CustomContainer>
    </Page>
  );
}

export default ReturnsDetailView;

import React from 'react';
import Page from '../Layout/Page';
import Error from './Error';

import CustomContainer from '../Material/CustomContainer';

function ErrorView({
  tabTitle,
  title,
  subTitle,
}) {
  return (
    <Page
      title={tabTitle}
    >
      <CustomContainer
        maxWidth='md'
      >
        <Error
          title={title}
          subTitle={subTitle}
        />
      </CustomContainer>
    </Page>
  );
}

export default ErrorView;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Box, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import {
  ChevronRight,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import useStyles from '../styles';
import { getOrderByBillingNumber, getOrdersAllBills } from '../../../requests/api/orders';
import handleApiResponse from '../../../utils/handleApiResponse';
import thousandSeparator from '../../../utils/thousandSeparator';
import { formatDate, formatTime } from '../../../utils/date';
import { setReturnsBill } from '../../../actions/returnsActions';

function DisplayItems() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [allBills, setAllBills] = useState([]);

  const { clientId } = useSelector((state) => state.account.user);
  const { id: selectedStoreId } = useSelector((state) => state.session.selectedStore);
  const { dateFilter, billNumberFilter } = useSelector((state) => state.returns);

  const getAllBillsFromOrder = async () => {
    try {
      const response = await getOrdersAllBills({
        storeId: selectedStoreId,
        clientId,
        dateFilter: dateFilter || '',
        billNumberFilter: billNumberFilter || '',
      });

      setAllBills(response.bills);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleSubmitReturn = async (billNumber) => {
    try {
      const response = await getOrderByBillingNumber({ billNumber });
      const { returns, ...rest } = response.orderByBillNum.order;
      let auxReturns = {};
      if (returns && returns.length > 0) {
        returns.forEach((itemOne) => {
          itemOne.returnProducts.forEach((item) => {
            if (auxReturns[item.productId]) {
              auxReturns = {
                ...auxReturns,
                [item.productId]: {
                  quantityReturned: item.amount + auxReturns[item.productId].quantityReturned,
                  unitPrice: item.unitPrice,
                },
              };
            } else {
              auxReturns = {
                ...auxReturns,
                [item.productId]: {
                  quantityReturned: item.amount,
                  unitPrice: item.unitPrice,
                },
              };
            }
          });
        });
      }
      dispatch(setReturnsBill({
        id: response.orderByBillNum.id,
        code: response.orderByBillNum.code,
        paymentAmount: response.orderByBillNum.paymentAmount,
        order: rest,
        returns: Object.keys(auxReturns).length > 0 ? auxReturns : null,
      }));
      history.push('/store-options/returns/detail');
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  useEffect(() => {
    getAllBillsFromOrder();
  }, []);

  useEffect(() => {
    getAllBillsFromOrder();
  }, [dateFilter, billNumberFilter]);

  return (
    <PerfectScrollbar
      style={{ flex: 1 }}>
      <Box
        minHeight='200px'
      >
        <Table stickyHeader>
          <colgroup>
            <col style={{ width: '32%' }} />
            <col style={{ width: '32%' }} />
            <col style={{ width: '31%' }} />
            <col style={{ width: '5%' }} />
          </colgroup>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={clsx(
                classes.tableHeaderTableCellLeft,
                classes.tableHeaderColor,
              )}>
                N° Boleta
              </TableCell>
              <TableCell className={clsx(
                classes.tableHeaderTableCell,
                classes.tableHeaderColor,
              )}>
                Fecha y Hora
              </TableCell>
              <TableCell className={clsx(
                classes.tableHeaderTableCell,
                classes.tableHeaderColor,
              )}>
                Monto
              </TableCell>
              <TableCell className={classes.tableHeaderColor} />
            </TableRow>
          </TableHead>
          <TableBody>
            {allBills && allBills.map((bill) => (
              <TableRow key={bill.id} onClick={() => handleSubmitReturn(bill.code)}>
                <TableCell className={clsx(classes.tableCellBodyLeft, classes.boldText)}>
                  <Box>
                    {bill.code}
                  </Box>
                </TableCell>
                <TableCell className={classes.tableCellBody}>
                  <Box
                    display='flex'
                    justifyContent='center'
                    flexDirection='column'
                  >
                    <Box className={classes.boldText}>
                      {formatDate(bill.createdAt)}
                    </Box>
                    <Box>
                      {formatTime(bill.createdAt)}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell className={clsx(classes.tableCellBody, classes.boldText)}>
                  {`$ ${thousandSeparator(bill.paymentAmount, '.')}`}
                </TableCell>
                <TableCell className={classes.noPadding}>
                    <ChevronRight
                      className={classes.chevronRightSymbol}
                    />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
  );
}

export default DisplayItems;
